import React from 'react';
import styled from "styled-components";
import Aux from "../Aux/Aux";
import Truck from "../../assets/icons/Truck";
import Laundromat from "../../assets/icons/Laundromat";
import Delivered from "../../assets/icons/Delivered";
import Machine from "../../assets/icons/Machine";
import Socks from "../../assets/icons/Socks";

const TrackingTable = (props) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    },
        atLaundromatDetails = props.details[ props.details.length - 2 ],
        laundromatDate = atLaundromatDetails?.statusUpdatedAt,
        atLaundromatDate = new Date( laundromatDate );

    let washingDate = new Date( atLaundromatDate ),
        dryingDate = new Date( atLaundromatDate ),
        foldingDate = new Date( atLaundromatDate );

    if( laundromatDate && atLaundromatDate?.toString() !== 'Invalid Date') {
        washingDate.setHours( atLaundromatDate.getHours() + 2 );
        dryingDate.setHours( atLaundromatDate.getHours() + 4 );
        foldingDate.setHours( atLaundromatDate.getHours() + 6 );
    }

    let rows = props.details.map((item, index) => (
        <Aux>
            {index === (props.details.length - 2) && 5 <= props.active && laundromatDate &&
                <tr>
                    <TD left={true}><TDContent><Icon><Socks/></Icon> Folding</TDContent></TD>
                    <TD right={true} align={"right"}>{foldingDate.toLocaleString('en-US', options).replace('at', '')}</TD>
                </tr>
            }
            {index === (props.details.length - 2) && 4 <= props.active && laundromatDate &&
                <tr>
                    <TD left={true}><TDContent><Icon><Machine/></Icon> Drying</TDContent></TD>
                    <TD right={true} align={"right"}>{dryingDate.toLocaleString('en-US', options).replace('at', '')}</TD>
                </tr>
            }
            {index === (props.details.length - 2) && 3 <= props.active && laundromatDate &&
                <tr>
                    <TD left={true}><TDContent><Icon><Machine/></Icon> Washing</TDContent></TD>
                    <TD right={true} align={"right"}>{washingDate.toLocaleString('en-US', options).replace('at', '')}</TD>
                </tr>
            }
            <tr>
                {item.status === "PICKED_UP_FROM_CUSTOMER" &&
                    <TD left={true}><TDContent><Icon><Truck/></Icon> Picked Up</TDContent></TD>}
                {item.status === "DROPPED_OFF_AT_LAUNDROMAT" &&
                    <TD left={true}><TDContent><Icon><Laundromat/></Icon> At Laundromat</TDContent></TD>}
                {item.status === "PICKED_UP_FROM_LAUNDROMAT" &&
                    <TD left={true}><TDContent><Icon><Truck/></Icon> In Route to Drop Off</TDContent></TD>}
                {item.status === "DELIVERED_TO_CUSTOMER" &&
                    <TD left={true}><TDContent><Icon><Delivered/></Icon> Delivered</TDContent></TD>}
                <TD right={true}
                    align={"right"}>{(new Date(item.statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}</TD>
            </tr>
        </Aux>
    ))

    return (
        <Aux>
            <TableHeader>Status Update</TableHeader>
            <Table>
                <TBody>
                    {rows}
                </TBody>
            </Table>
        </Aux>
    );
};

export default TrackingTable;

const TDContent = styled.div`
    display: flex;
    align-items: center;
`;

const Icon = styled.div`
    display: inline-block;
    margin-right: 10px;

    & svg {
        width: 25px;
        height: 25px;
    }
`;

const TableHeader = styled.div`
    margin: 10px 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
`;

const Table = styled.table`
`;

const TBody = styled.tbody`
    & tr:nth-child(even) {
        background-color: #f3f3f3;
    }
`;

const TD = styled.td`
    text-align: ${props => props.align || "left"};
    padding: 10px 15px;

    border-bottom-left-radius: ${props => props.left ? "40px" : 0};
    border-top-left-radius: ${props => props.left ? "40px" : 0};

    border-bottom-right-radius: ${props => props.right ? "40px" : 0};
    border-top-right-radius: ${props => props.right ? "40px" : 0};
`;
