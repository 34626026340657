import React, {useState} from "react";
import styled from "styled-components"
import FormContainer from "../Form/Container/v1/FormContainer";
import Button from "../Form/Button/v1/Button";
import Input from "../Form/Input/v1/Input";
import PageContainer from "../utility/containers/PageContainer";
import PageTitle from "../utility/text/PageTitle";
import PageSubheader from "../utility/text/PageSubheader";
import Address from "../../assets/icons/Address";
import Unit from "../../assets/icons/Unit";
import City from "../../assets/icons/City";
import State from "../../assets/icons/State";
import Mail from "../../assets/icons/Mail";
import {useSelector} from "react-redux";
import Aux from "../Aux/Aux";
import PageWidthContainer from "../utility/containers/PageWidthContainer";
import Image from "../Image/v1/Image";
import AddressImg from "../../assets/images/background/Address.png";
import TeaserCard from "../utility/containers/TeaserCard";
import {Paragraph, Text} from "../utility/helper/Paragraph";

const AddressInfo = (props) => {
    const addressError = useSelector( (state) => state.signup.addressError );
    const [formError, setFormError] = useState({
        addressLine1: false,
        addressLine2: false,
        city: false,
        state: false,
        zipCode: false,
    });
    const validationRegex = {
        addressLine1: '',
        addressLine2: '',
        city: /^[A-Za-zÀ-ÖØ-öø-ÿ\'’\\-\\s\s]+$/,
        state: /^[A-Za-z]{2}$/,
        zipCode: /^\d{5}(-\d{4})?$/,
    };

    // const handleAddressSubmit = () => {
    //     const validateAddress = async () => {
    //         dispatch( addressValidation({
    //             body: {
    //                 addressLine1: props.formData[ "addressLine1" ],
    //                 addressLine2: props.formData[ "addressLine2" ],
    //                 city: props.formData[ "city" ],
    //                 state: props.formData[ "state" ],
    //                 zipCode: props.formData[ "zipCode" ],
    //                 country: props.formData[ "country" ]
    //             },
    //             loading: props.isLoading,
    //             setStep: props.handleNext,
    //         }) )
    //     }
    //
    //     props.isLoading(true);
    //     validateAddress();
    // }
    //
    const hasErrors = () => {
        let keys = Object.keys( formError );
        let error = false;
        for( let key of keys ) {
            error = error || formError[ key ];
            if( !props.formData[ key ] || ( validationRegex[key] && !validationRegex[ key ].test( props.formData[ key ]) ) ) {
                error = true;
                setError( key );
            }
        }
        return error;
    }

    const setError = (name) => {
        setFormError(prevState => ({
            ...prevState,
            [name]: true
        }));
    }

    const clearError = (name) => {
        setFormError(prevState => ({
            ...prevState,
            [name]: false
        }));
    }

    return (
        <Aux>
            { (props.screenType === "Desktop" || props.screenType === "Mobile") &&
                <Aux>
                    <PageTitle alignment={"center"}>Address Validation</PageTitle>
                    <PageSubheader alignment={"center"}>Enter your address to see if we service your area</PageSubheader>
                </Aux>
            }
            { props.screenType === "Tablet" &&
                <PageWidthContainer
                    width={100}
                    height={"333px"}
                    display={true}
                >
                    <Image
                        cssUrl={AddressImg}
                        height={"333px"}
                    />
                    <TeaserCard>
                        <PageTitle margin={"none"} alignment={"center"}>Address Validation</PageTitle>
                        <PageSubheader alignment={"center"}>Enter your address to see if we service your area</PageSubheader>
                    </TeaserCard>
                </PageWidthContainer>
            }
            <PageContainer>
                <FormContainer errorMessage={addressError}>
                    <Input
                        name={"addressLine1"}
                        type={"text"}
                        regex={validationRegex.addressLine1}
                        labelText={"Address"}
                        required={true}
                        screenType={props.screenType}
                        placeholder={"123 Main St"}
                        hasError={formError.addressLine1}
                        value={props.formData.addressLine1}
                        setError={setError}
                        clearError={clearError}
                        onChange={props.handleChange}
                        errorMessage={"Please enter a valid Address"}
                    >
                        <Address />
                    </Input>
                    <Input
                        name={"addressLine2"}
                        type={"text"}
                        regex={validationRegex.addressLine2}
                        labelText={"Unit Number"}
                        required={true}
                        screenType={props.screenType}
                        placeholder={"Apt 4B"}
                        hasError={formError.addressLine2}
                        value={props.formData.addressLine2}
                        setError={setError}
                        clearError={clearError}
                        onChange={props.handleChange}
                        errorMessage={"Please enter a valid Unit Number"}
                    >
                        <Unit />
                    </Input>
                    <TwoColumn>
                        <Input
                            name={"city"}
                            type={"text"}
                            regex={validationRegex.city}
                            labelText={"City"}
                            required={true}
                            screenType={props.screenType}
                            placeholder={"New York"}
                            hasError={formError.city}
                            value={props.formData.city}
                            setError={setError}
                            clearError={clearError}
                            onChange={props.handleChange}
                            errorMessage={"Please enter a valid City"}
                            half={true}
                            side={"flex-end"}
                        >
                            <City />
                        </Input>
                        <Input
                            name={"state"}
                            type={"tel"}
                            regex={validationRegex.state}
                            labelText={"State"}
                            required={true}
                            readOnly={true}
                            screenType={props.screenType}
                            placeholder={"NY"}
                            hasError={formError.state}
                            value={props.formData.state}
                            setError={setError}
                            clearError={clearError}
                            onChange={props.handleChange}
                            errorMessage={"Please enter a valid 2 digit State Code"}
                            half={true}
                            side={"flex-start"}
                        >
                            <State />
                        </Input>
                    </TwoColumn>
                    <Input
                        name={"zipCode"}
                        type={"tel"}
                        regex={validationRegex.zipCode}
                        labelText={"Zip Code"}
                        required={true}
                        screenType={props.screenType}
                        placeholder={"10001"}
                        hasError={formError.zipCode}
                        value={props.formData.zipCode}
                        setError={setError}
                        clearError={clearError}
                        onChange={props.handleChange}
                        errorMessage={"Please enter a valid Zip Code"}
                    >
                        <Mail />
                    </Input>
                </FormContainer>
                <Paragraph align={"left"}><Text>Please go to our Frequently Asked Questions to learn more about what apartments service and when we may start service on your complex</Text></Paragraph>
                <Button
                    id={"submit"}
                    hasErrors={hasErrors}
                    submit={props.handleNext}
                >
                    Validate Address
                </Button>
                <Button
                    submit={props.handleBack}
                    inverse={true}
                >
                    Back
                </Button>
            </PageContainer>
        </Aux>
    )
}

export default AddressInfo;

const TwoColumn = styled.div`
    display: flex;
    gap: 16px;
    
    @media (max-width: 768px) {
        display: block;
    }
`;
