import React from "react";
import SVG from "./SVG";

const Checkmark = (props) => {
    return (
        <SVG viewBox="0 0 150 150" width="150" height="150" fill="none">
            <rect/>
            <g opacity="100%">
                <ellipse cx="75" cy="75" rx="73.5" ry="73.5" fill="rgba(76,217,100,0)" stroke="#4cd964" stroke-width="3"
                         filter="url(#filter_dshadow_0_0_0_00000014)"/>
            </g>
            <svg xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 24 24" fill="#4cd964" x="25"
                 y="25" opacity="100%">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
            </svg>
            <defs>
                <filter id="filter_dshadow_10_0_2_0000001a" color-interpolation-filters="sRGB"
                        filterUnits="userSpaceOnUse">
                    <feFlood flood-opacity="0" result="bg-fix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="alpha"/>
                    <feOffset dx="0" dy="2"/>
                    <feGaussianBlur stdDeviation="5"/>
                    <feComposite in2="alpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="bg-fix" result="bg-fix-filter_dshadow_10_0_2_0000001a"/>
                    <feBlend in="SourceGraphic" in2="bg-fix-filter_dshadow_10_0_2_0000001a" result="shape"/>
                </filter>
                <filter id="filter_dshadow_0_0_0_00000014" color-interpolation-filters="sRGB"
                        filterUnits="userSpaceOnUse">
                    <feFlood flood-opacity="0" result="bg-fix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="alpha"/>
                    <feOffset dx="0" dy="0"/>
                    <feGaussianBlur stdDeviation="0"/>
                    <feComposite in2="alpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                    <feBlend mode="normal" in2="bg-fix" result="bg-fix-filter_dshadow_0_0_0_00000014"/>
                    <feBlend in="SourceGraphic" in2="bg-fix-filter_dshadow_0_0_0_00000014" result="shape"/>
                </filter>
            </defs>
        </SVG>)
}

export default Checkmark;
