import React from "react";
import styled from "styled-components";
import {Paragraph, Text} from "../helper/Paragraph";
import WarningIcon from "../../../assets/icons/WarningIcon";

const ErrorMessage = (props) => {
    return (
        <Paragraph>
            <Text>
                <Message>
                    <WarningIcon />
                    {props.children}
                </Message>
            </Text>
        </Paragraph>
    )
}

export default ErrorMessage;

const Message = styled.div`
    padding: 10px;
    width: 100%;
    background-color: #fd4949;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    
    & > svg {
        color: #ffffff !important;
        fill: #ffffff !important;
        width: 40px !important;
        height: 32px !important;
        margin-right: 10px;
    }
`;
