import React from "react";
import styled from "styled-components";

const BackgroundOverlay = (props) => {
    return (
        <BackgroundDiv>
            {props.children}
        </BackgroundDiv>
    )
}

export default BackgroundOverlay;

const BackgroundDiv = styled.div`
    position: fixed;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #92929225;
    z-index: -1;
`;
