import {put} from "redux-saga/effects";
import {
    clearCustomerDetails,
    setEmailVerified,
    setPhoneVerified,
    updateCustomerDetails,
    updateDetails
} from "../reducers/customer";
import {logout} from "../reducers/auth";

export function* handleCustomerDetails( action ) {
    try {
        const getResponse = yield fetch(process.env.REACT_APP_CUSTOMER_DETAILS, {
            method: 'GET',
            credentials: 'include',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
        })

        const data = yield getResponse.json();

        if (!getResponse.ok) {
            if( getResponse.status === 403 ) {
                yield put( logout() )
            }
            const errorMessage = data.message || "Something went wrong when retrieving customer details";
            throw Error(errorMessage);
        }
        yield put( updateDetails(data) );
    }
    catch (e) {
        console.log( e.toString() );
        yield put( clearCustomerDetails() );
    }
    yield action.payload.setLoading( false );
}

export function* handleSendEmailVerification( action ) {
    try {
        const getResponse = yield fetch(process.env.REACT_APP_CUSTOMER_SEND_EMAIL_VERIFICATION, {
            method: 'POST',
            credentials: 'include',
        })

        if (!getResponse.ok) {
            if( getResponse.status === 403 ) {
                yield put( logout() )
            }
            const data = yield getResponse.json();
            const errorMessage = data.message || "Something went wrong when attempting to send email verification";
            throw Error(errorMessage);
        }
        yield action.payload.setError( "" );
        yield action.payload.verificationSent( true );
        yield action.payload.setSuccess( "Verification code has been sent!" );
    }
    catch (e) {
        console.log( e.toString() );
        yield action.payload.setError( e.toString() );
        yield action.payload.verificationSent( false );
    }
    yield action.payload.setInput(prevState => ({
        ...prevState,
        emailverificationcode: ""
    }));
    yield action.payload.setIndex( -1 );
    yield action.payload.setLoading( false );
}

export function* handleVerifyEmail( action ) {
    console.log("email verify start")
    try {
        const getResponse = yield fetch(process.env.REACT_APP_CUSTOMER_VALIDATE_EMAIL_VERIFICATION, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify(action.payload.body)
        })

        if (!getResponse.ok) {
            if( getResponse.status === 403 ) {
                yield put( logout() )
            }
            const data = yield getResponse.json();
            const errorMessage = data.message || "Something went wrong when trying to validate your email";
            throw Error(errorMessage);
        }
        console.log("email verify success")

        yield put(setEmailVerified( { isEmailVerified: true} ));
        yield action.payload.setSuccess( "Email Verification.js Successful" );
        yield action.payload.setError( "" );
    }
    catch (e) {
        console.log("email verify error")
        console.log( e.toString() );
        yield put(setEmailVerified( { isEmailVerified: false} ));
        yield action.payload.setError( e.toString() );
    }
    yield action.payload.setInput(prevState => ({
        ...prevState,
        emailverificationcode: ""
    }));
    yield action.payload.setIndex( -1 );
    yield action.payload.setLoading( false );
}

export function* handleSendPhoneVerification( action ) {
    try {
        const getResponse = yield fetch(process.env.REACT_APP_CUSTOMER_SEND_PHONE_VERIFICATION, {
            method: 'POST',
            credentials: 'include',
        })

        if (!getResponse.ok) {
            if( getResponse.status === 403 ) {
                yield put( logout() )
            }
            const data = yield getResponse.json();
            const errorMessage = data.message || "Something went wrong when attempting to send email verification";
            throw Error(errorMessage);
        }
        yield action.payload.setError( "" );
        yield action.payload.verificationSent( true );
        yield action.payload.setSuccess( "Verification code has been sent!" );
    }
    catch (e) {
        console.log( e.toString() );
        yield action.payload.setError( e.toString() );
        yield action.payload.verificationSent( false );
    }
    yield action.payload.setInput(prevState => ({
        ...prevState,
        phoneverificationcode: ""
    }));
    yield action.payload.setIndex( -1 );
    yield action.payload.setLoading( false );
}

export function* handleVerifyPhone( action ) {
    try {
        const getResponse = yield fetch(process.env.REACT_APP_CUSTOMER_VALIDATE_PHONE_VERIFICATION, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify(action.payload.body)
        })

        if (!getResponse.ok) {
            if( getResponse.status === 403 ) {
                yield put( logout() )
            }
            const data = yield getResponse.json();
            const errorMessage = data.message || "Something went wrong when trying to validate your email";
            throw Error(errorMessage);
        }

        yield put(setPhoneVerified( { isPhoneNumberVerified: true} ));
        yield action.payload.setSuccess( "Email Verification.js Successful" );
        yield action.payload.setError( "" );
    }
    catch (e) {
        console.log( e.toString() );
        yield put(setPhoneVerified( { isPhoneNumberVerified: false} ));
        yield action.payload.setError( e.toString() );
    }
    yield action.payload.setInput(prevState => ({
        ...prevState,
        emailverificationcode: ""
    }));
    yield action.payload.setIndex( -1 );
    yield action.payload.setLoading( false );
}

export function* handleUpdateCustomerInfo( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_CUSTOMER_UPDATE_PROFILE, {
            method: 'PATCH',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify( action.payload.body )
        })

        if (!postResponse.ok) {
            const data = yield postResponse.json();
            const errorMessage = data.message || "Something went wrong when attempting to Update";
            throw Error(errorMessage);
        }

        yield put(updateCustomerDetails({
            firstname: action.payload.body.firstname,
            lastname: action.payload.body.lastname,
            addressLine1: action.payload.body.addressLine1,
            addressLine2: action.payload.body.addressLine2,
            city: action.payload.body.city,
            state: action.payload.body.state,
            zipCode: action.payload.body.zipCode,
            country: action.payload.body.country,
        }))
        yield action.payload.setSuccess( "Information Updated Successfully" );
        yield action.payload.setError( "" );
        yield action.payload.editProfile( false );
        yield action.payload.editAddress( false );
    }
    catch (e) {
        const error = e.toString();
        yield action.payload.setError( error );
        yield action.payload.setSuccess( "" );
    }
    yield action.payload.setLoading( false )
}

export function* handleChangePassword( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_CUSTOMER_CHANGE_PASSWORD, {
            method: 'POST',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify( action.payload.body )
        })

        if (!postResponse.ok) {
            const data = yield postResponse.json();
            const errorMessage = data.message || "Something went wrong when attempting to Update";
            throw Error(errorMessage);
        }

        yield action.payload.setSuccess( "Information Updated Successfully" );
        yield action.payload.setError( "" );
        yield action.editPassword( false );
    }
    catch (e) {
        const error = e.toString();
        yield action.payload.setError( error );
        yield action.payload.setSuccess( "" );
    }
    yield action.payload.setLoading( false )
}
