import React, {useEffect, useState} from "react";
import styled from "styled-components"

const Calendar = (props) => {
    const [daysInMonth, setDaysInMonth] = useState([]);
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const date = new Date(props.invoiceDate);
    const paymentDate = date.getDate();

    useEffect(() => {
        generateCalendar();
    }, []);

    const generateCalendar = () => {
        const date = new Date(props.invoiceDate);
        const year = date.getFullYear();
        const month = date.getMonth(); // 0-indexed, 0 is January
        const firstDay = new Date(year, month, 1).getDay(); // Day of the week the month starts on
        const lastDate = new Date(year, month + 1, 0).getDate(); // Last day of the month

        // Create an array for the calendar days
        const daysArray = [];

        // Add empty slots for the days before the first day of the month
        for (let i = 0; i < firstDay; i++) {
            daysArray.push(null); // null indicates an empty slot
        }

        // Add the days of the month
        for (let day = 1; day <= lastDate; day++) {
            daysArray.push(day);
        }

        setDaysInMonth(daysArray);
    };

    return (
        <div className={"calendar"}>
            <DaysOfWeek>
                {daysOfWeek.map((day, index) => (
                    <DaysHeader key={index}>
                        {day}
                    </DaysHeader>
                ))}
            </DaysOfWeek>
            <CalendarGrid>
                {daysInMonth.map((day, index) => (
                    <CalendarDayParent  key={index}>
                        <CalendarDay isActive={day === paymentDate}>
                            {day ? day : ''}
                        </CalendarDay>
                    </CalendarDayParent>
                ))}
            </CalendarGrid>
        </div>
    );
}

export default Calendar;

const DaysOfWeek = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    font-weight: bold;
    text-align: center;
`;

const DaysHeader = styled.div`
    padding: 10px 0;
`;

const CalendarGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 5px;
`;

const CalendarDayParent = styled.div`
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CalendarDay = styled.div`
    width: fit-content;
    background-color: ${props => props.isActive ? "#212121" : "transparent"};
    color: ${props => props.isActive ? "#ffffff" : "#212121"};
    padding: 5px;
    border-radius: 50px;
`;
