import { createSlice } from '@reduxjs/toolkit';

export const signupSlice = createSlice({
    name: 'signup',
    initialState: {
        successMessage: "",
        error: "",
        addressError: "",
        usernameError: "",
    },
    reducers: {
        updateState: (state, action) => {
            state.error = action.payload.error;
            state.successMessage = action.payload.successMessage;
            state.usernameError = action.payload.usernameError || "";
            state.addressError = action.payload.addressError || "";
        },
        signup: () => {},
        addressValidation: () => {},
        usernameValidation: () => {}
    },
})

export const {
    signup,
    updateState,
    addressValidation,
    usernameValidation
} = signupSlice.actions

export default signupSlice.reducer;
