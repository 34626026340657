import {put} from "redux-saga/effects";
import {updateState} from "../reducers/signup";
import {login} from "../reducers/auth";

export function* signupCustomer( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_CUSTOMER_SIGNUP, {
            method: 'POST',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify( action.payload.body )
        })

        const data = yield postResponse.json();
        if (!postResponse.ok) {
            const errorMessage = data.message || "Something went wrong when attempting to signup customer";
            throw Error(errorMessage);
        }

        yield put(updateState({"error": "", "successMessage": "Success"}));

        yield put(login({
            "role": data["role"],
        } ));
    }
    catch (e) {
        const error = e.toString();
        yield put( updateState({error, "successMessage": ""}) );
    }

    yield action.payload.setLoading( false );
}

export function* handleUsernameValidation( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_CUSTOMER_USERNAME_VALIDATION, {
            method: 'POST',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify( action.payload.body )
        })


        if (!postResponse.ok) {
            const data = yield postResponse.json();
            const errorMessage = data.message || "Something went wrong when attempting to signup customer";
            throw Error(errorMessage);
        }

        yield put(updateState({"usernameError": "", "successMessage": ""}));
        yield action.payload.setStep( action.payload.nextStep );
        yield action.payload.setImageLeft( true );
    }
    catch (e) {
        const usernameError = e.toString();
        yield put( updateState({usernameError, "successMessage": ""}) );
    }
    yield action.payload.loading(false)
}

export function* handleAddressValidation( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_CUSTOMER_ADDRESS_VALIDATION, {
            method: 'POST',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify( action.payload.body )
        })

        const data = yield postResponse.json();

        if (!postResponse.ok) {
            const errorMessage = data.message || "Something went wrong when attempting to signup customer";
            throw Error(errorMessage);
        }

        yield put(updateState({"addressError": "", "successMessage": ""}));
        yield action.payload.setStep( action.payload.nextStep );
        yield action.payload.setImageLeft( true );
    }
    catch (e) {
        const addressError = e.toString();
        yield put( updateState({addressError, "successMessage": ""}) );
    }
    yield action.payload.loading(false)
}
