import React from "react";
import {useDispatch} from "react-redux";
import {handleLogout} from "../../../../store/reducers/auth";
import styled from "styled-components";

const Logout = (props) => {
    const dispatch = useDispatch();

    const handleSubmit = () => {
        const logoutCall = async () => {
            await dispatch( handleLogout() );
        }

        props?.isLoading(true);
        setTimeout(function() {
            logoutCall().then( () => {
                props?.isLoading(false)
            } )
        }, 10);
    }

    return (
        <LogoutParent>
            <LogoutButton onClick={handleSubmit}>
                Logout
            </LogoutButton>
        </LogoutParent>
    )
}

export default Logout;

const LogoutButton = styled.div`
    height: 40px;
    width: 82px;
    background-color: #212121;
    color: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LogoutParent = styled.div`
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 850px) {
        display: inline-block;
    }
`;
