import React from "react";
import styled from "styled-components"

const Spinner = () => {
    return (
        <SpinnerOuter>
            <SpinnerInner />
        </SpinnerOuter>
    )
}

export default Spinner;

const SpinnerOuter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000000000;
    background-color: black;
    opacity: .8;
`;

const SpinnerInner = styled.div`
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg);}
        100% { transform: rotate(360deg); }
    }
`;