import React from "react";

const Cancel = (props) => {
    return (
        <svg viewBox="0 0 40 40"
             width="40" height="40" fill="none">
            <rect/>
            <g opacity="100%">
                <rect x="10" y="10" width="20" height="20" fill="#fff" rx="0"
                      filter="url(#filter_dshadow_0_0_0_00000014)"/>
            </g>
            <svg height="40" width="40" viewBox="0 0 24 24" fill="#f00" x="0" y="0"
                 opacity="100%">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path
                    d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/>
            </svg>
            <defs>
                <filter id="filter_dshadow_0_0_0_00000014" color-interpolation-filters="sRGB"
                        filterUnits="userSpaceOnUse">
                    <feFlood flood-opacity="0" result="bg-fix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="alpha"/>
                    <feOffset dx="0" dy="0"/>
                    <feGaussianBlur stdDeviation="0"/>
                    <feComposite in2="alpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                    <feBlend mode="normal" in2="bg-fix" result="bg-fix-filter_dshadow_0_0_0_00000014"/>
                    <feBlend in="SourceGraphic" in2="bg-fix-filter_dshadow_0_0_0_00000014" result="shape"/>
                </filter>
                <filter id="filter_dshadow_10_0_2_0000001a" color-interpolation-filters="sRGB"
                        filterUnits="userSpaceOnUse">
                    <feFlood flood-opacity="0" result="bg-fix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="alpha"/>
                    <feOffset dx="0" dy="2"/>
                    <feGaussianBlur stdDeviation="5"/>
                    <feComposite in2="alpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="bg-fix" result="bg-fix-filter_dshadow_10_0_2_0000001a"/>
                    <feBlend in="SourceGraphic" in2="bg-fix-filter_dshadow_10_0_2_0000001a" result="shape"/>
                </filter>
            </defs>
        </svg>)
}

export default Cancel;
