import {put} from "redux-saga/effects";
import {updateAddresses} from "../reducers/employee";

export function* employeeAddressSearch( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_EMPLOYEE_PENDING_DELIVERIES, {
            method: 'GET',
            credentials: 'include'
        })

        const data = yield postResponse.json();

        if( !postResponse.ok ) {
            const errorMessage = data.message || "Unexpected Error Occurred";
            throw Error(errorMessage);
        }
        yield put(updateAddresses({
            error: "",
            uniqueAddresses: data.uniqueAddresses,
            pendingDeliveries: data.pendingDeliveries,
        }));
    }
    catch (e) {
        const error = e.toString();
        yield updateAddresses({
            error: error,
            uniqueAddresses: [],
            pendingDeliveries: [],
        })
    }

    yield action.payload.setLoading( false );
}
