import React from "react";
import SVG from "./SVG";

const key = (props) => {
    return (
        <SVG viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none">
            </path>
            <path
                d="M12.65 10A5.99 5.99 0 0 0 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6a5.99 5.99 0 0 0 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z">
            </path>
        </SVG>)
}

export default key;
