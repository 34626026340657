import React, {useState} from "react";
import FormContainer from "../Form/Container/v1/FormContainer";
import Button from "../Form/Button/v1/Button";
import Input from "../Form/Input/v1/Input";
import PageContainer from "../utility/containers/PageContainer";
import PageTitle from "../utility/text/PageTitle";
import PageSubheader from "../utility/text/PageSubheader";
import Aux from "../Aux/Aux";
import PageWidthContainer from "../utility/containers/PageWidthContainer";
import Image from "../Image/v1/Image";
import PasswordImg from "../../assets/images/background/Password.png";
import TeaserCard from "../utility/containers/TeaserCard";
import {PasswordText, Text} from "../utility/helper/Paragraph";
import Key from "../../assets/icons/Key";
import PasswordStrength from "./PasswordStrength";

const PasswordInfo = (props) => {
    const [formError, setFormError] = useState({
        password: false,
        confirmPassword: false
    });
    const validationRegex = {
        password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W]{8,}$/,
        confirmPassword: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W]{8,}$/,
    };
    let strength = 0;

    if( /[A-Za-z]/.test(props.formData.password) ) {
        strength++;
    }
    if( /\d/.test(props.formData.password) ) {
        strength++;
    }
    if( /^.{8,}$/.test(props.formData.password) ) {
        strength++;
    }
    if( /[^A-Za-z0-9]/.test(props.formData.password) ) {
        strength++;
    }

    const hasErrors = () => {
        let keys = Object.keys( formError );
        let error = false;
        for( let key of keys ) {
            error = error || formError[ key ];
            if( !props.formData[ key ] || ( validationRegex[key] && !validationRegex[ key ].test( props.formData[ key ]) ) ) {
                error = true;
                setError( key );
            }
            if( key === "confirmPassword" && props.formData[ key ] !== props.formData[ "password" ] ) {
                error = true;
                setError( key );
            }
        }
        return error;
    }

    const setError = (name) => {
        setFormError(prevState => ({
            ...prevState,
            [name]: true
        }));
    }

    const clearError = (name) => {
        setFormError(prevState => ({
            ...prevState,
            [name]: false
        }));
    }

    return (
        <Aux>
            { (props.screenType === "Desktop" || props.screenType === "Mobile") &&
                <Aux>
                    <PageTitle alignment={"center"}>Create an account</PageTitle>
                    <PageSubheader alignment={"center"}>To get started, enter your personal details</PageSubheader>
                </Aux>
            }
            { props.screenType === "Tablet" &&
                <PageWidthContainer
                    width={100}
                    height={"333px"}
                    display={true}
                >
                    <Image
                        cssUrl={PasswordImg}
                        height={"333px"}
                    />
                    <TeaserCard>
                        <PageTitle margin={"none"} alignment={"center"}>Create an account</PageTitle>
                        <PageSubheader alignment={"center"}>To get started, enter your personal details</PageSubheader>
                    </TeaserCard>
                </PageWidthContainer>
            }
            <PageContainer>
                <FormContainer>
                    <Input
                        name={"password"}
                        type={"password"}
                        regex={validationRegex.password}
                        labelText={"Password"}
                        required={true}
                        screenType={props.screenType}
                        placeholder={"********"}
                        hasError={formError.password}
                        value={props.formData.password}
                        setError={setError}
                        clearError={clearError}
                        onChange={props.handleChange}
                        errorMessage={"Please make sure you follow our password requirements"}
                    >
                        <Key />
                    </Input>
                    <PasswordText><Text>Ensure your password is at least 8 characters long and includes a mix of letters, numbers, and special characters for better security</Text></PasswordText>
                    <PasswordStrength strengthNumber={strength}/>
                    <Input
                        name={"confirmPassword"}
                        type={"password"}
                        regex={validationRegex.confirmPassword}
                        labelText={"Confirm Password"}
                        required={true}
                        screenType={props.screenType}
                        placeholder={"********"}
                        hasError={formError.confirmPassword}
                        value={props.formData.confirmPassword}
                        setError={setError}
                        clearError={clearError}
                        onChange={props.handleChange}
                        errorMessage={"Please make sure this matches your password"}
                    >
                        <Key />
                    </Input>
                    <Button
                        id={"submit"}
                        hasErrors={hasErrors}
                        submit={props.handleNext}
                    >
                        Register Now
                    </Button>
                    <Button
                        submit={props.handleBack}
                        inverse={true}
                    >
                        Back
                    </Button>
                </FormContainer>
            </PageContainer>
        </Aux>
    )
}

export default PasswordInfo;