import React, {useEffect, useState} from 'react';
import Spinner from "../../../components/Spinner/v1/Spinner";
import EmployeeHome from "../../../pages/Employee/EmployeeHome";
import styled from "styled-components";
import {Route, Routes} from "react-router-dom";
import NotFound from "../../../pages/NotFound/v1/NotFound";
import EmployeeTracking from "../../../pages/Employee/EmployeeTracking";
import EmployeeAddressList from "../../../pages/Employee/EmployeeAddressList";
import {useSelector} from "react-redux";

const EmployeeRoute = (props) => {
    const [isLoading, setIsLoading] = useState(false),
        isAuthenticated = useSelector( (state) => state.auth.isAuthenticated );

    useEffect(() => {
        if( !isAuthenticated ) {
            window.location.href = "/login"
        }
    }, []);

    const handleLoading = (value) => {
        setIsLoading(value);
    }

    return (
        <EmployeePage>
            {isLoading && <Spinner />}
            <Routes>
                <Route path={"/"} exact element={ <EmployeeHome screenType={props.screenType} isShortContent={props.isShortContent} /> } />
                <Route path={"/update-tracking"} exact handleLoading={handleLoading} element={ <EmployeeTracking isShortContent={props.isShortContent} /> } />
                <Route path={"/address-list"} exact handleLoading={handleLoading} element={ <EmployeeAddressList screenType={props.screenType} isShortContent={props.isShortContent} /> } />
                <Route path={"/*"} element={ <NotFound isShortContent={props.isShortContent} /> } />
            </Routes>
        </EmployeePage>
    );
};

export default EmployeeRoute;

const EmployeePage = styled.div`
    width: 100vw;
    height: 100%;
    overflow: hidden;
`;
