import React from "react";
import SVG from "./SVG";

const WarningIcon = (props) => {
    return (
        <SVG viewBox="0 0 24 24">
            <path d="M0 0h24v24H0V0z" fill="none">
            </path>
            <path
                d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9 14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8z">
            </path>
            <path d="M12 15a1 1 0 1 0 0 2 1 1 0 1 0 0-2zM11 7h2v7h-2z">
            </path>
        </SVG>)
}

export default WarningIcon;
