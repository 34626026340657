import React from "react";
import Aux from "../../components/Aux/Aux";
import PageTitle from "../../components/utility/text/PageTitle";
import DefaultHeader from "../../components/Headers/DefaultHeader";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import PageWidthContainer from "../../components/utility/containers/PageWidthContainer";
import Hero from "../../assets/images/background/Privacy_Policy.jpg"
import styled from "styled-components";
import PageSubheader from "../../components/utility/text/PageSubheader";
import Image from "../../components/Image/v1/Image";
import {AlwaysCard, Card} from "../../components/utility/containers/Card";
import PageContainer from "../../components/utility/containers/PageContainer";
import {Paragraph} from "../../components/utility/helper/Paragraph";

const PrivacyPolicy = (props) => {
    return (
        <Aux>
            <DefaultHeader />
            <PageWidthContainer
                width={100}
                height={ props.screenType === "Mobile" ? "250px" : "400px"}
                display={true}
            >
                <Image
                    cssUrl={Hero}
                    height={"100%"}
                />
                <Absolute>
                    <AlwaysCard>
                        <PageTitle margin={"none"} alignment={"center"}>Privacy Policy</PageTitle>
                    </AlwaysCard>
                </Absolute>
            </PageWidthContainer>
            <PageContainer>
                <Section>
                    <Title>Introduction</Title>
                    <Paragraph align={"left"}>
                        The Origami Company ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our website theorigamicompany.com (collectively, "Services"). By accessing or using the Services, you agree to the terms of this Privacy Policy.
                    </Paragraph>
                </Section>
                <Section>
                    <Title>Information We Collect</Title>
                    <ListParagraph>
                        We may collect the following types of information:
                        <ul>
                            <li>
                                <b>Personal Information:</b>
                                <br/>
                                Information that identifies you personally, such as your name, email address, and phone
                                number, which you provide when registering or using the Services.
                            </li>
                            <br/>
                            <li>
                                <b>Usage Data:</b>
                                <br/>
                                Information about how you use the Services, including your IP address, browser
                                type, operating system, pages visited, and timestamps.
                            </li>
                            <br/>
                            <li>
                                <b>Communication Data:</b>
                                <br/>
                                Records of messages sent and received through our Services, including
                                any data associated with those messages.
                            </li>
                        </ul>
                    </ListParagraph>
                </Section>
                <Section>
                    <Title>How We Use Your Information</Title>
                    <ListParagraph>
                        We may use the information we collect for various purposes, including:
                        <ul>
                            <li>
                                To provide and maintain our Services and its functionality
                            </li>
                            <br/>
                            <li>
                                To communicate with you, including sending notifications and updates
                            </li>
                            <br/>
                            <li>
                                To respond to your inquiries and provide customer support
                            </li>
                            <br/>
                            <li>
                                To analyze usage patterns and improve our Services
                            </li>
                            <br/>
                            <li>
                                To comply with legal obligations and regulatory requirements
                            </li>
                        </ul>
                    </ListParagraph>
                </Section>
                <Section>
                    <Title>Data Sharing and Disclosure</Title>
                    <ListParagraph>
                        We do not sell your personal information to third parties. We may share your information with:
                        <ul>
                            <li>
                                <b>Service Providers</b>
                                <br/>
                                Third-party vendors, including Twilio, who assist us in operating the Services and providing services to you, subject to their own privacy policies.
                            </li>
                            <br/>
                            <li>
                                <b>Legal Compliance:</b>
                                <br/>
                                We may disclose your information if required by law or in response to valid requests by public authorities.
                            </li>
                        </ul>
                    </ListParagraph>
                </Section>
                <Section>
                    <Title>Data Security</Title>
                    <Paragraph align={"left"}>
                        We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to protect your information, we cannot guarantee its absolute security.
                    </Paragraph>
                </Section>
                <Section>
                    <Title>Your Rights</Title>
                    <ListParagraph>
                        Depending on your location, you may have certain rights regarding your personal information, including:
                        <ul>
                            <li>
                                The right to access, correct, or delete your information
                            </li>
                            <br/>
                            <li>
                                The right to restrict or object to our processing of your information
                            </li>
                            <br/>
                            <li>
                                The right to data portability
                            </li>
                        </ul>
                        To exercise these rights, please contact us using the information provided below.
                    </ListParagraph>
                </Section>
                <Section>
                    <Title>Changes to This Privacy Policy</Title>
                    <Paragraph align={"left"}>
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                    </Paragraph>
                </Section>
                <Section>
                    <Title>Contact Us</Title>
                    <ListParagraph>
                        If you have any questions about this Privacy Policy, please contact us at:
                        <br/>
                        <br/>
                        <b>The Origami Company</b><br/>
                        12670 Jebbia Ln, Stafford, TX 77477, US<br/>
                        admin@theorigamicompany.com<br/>
                        832-818-2062<br/>
                    </ListParagraph>
                </Section>
            </PageContainer>
            <DefaultFooter isShortContent={props.isShortContent}/>
        </Aux>
    )
}

export default PrivacyPolicy;

export const ListParagraph = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    margin: 10px 0;

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 13px;
    }
`;

const Section = styled.div`
    padding: 10px 100px;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const Title = styled.h2`
    font-weight: bolder;
`;

const Absolute = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
        width: 80%;
    }
`;
