import React from "react";
import styled from "styled-components";

const PageContainer = (props) => {
    return (
        <Container>
            {props.children}
        </Container>
    )
}

export default PageContainer;

const Container = styled.div`
    padding: 15px 50px;
    margin-top: 10px;
    width: 100%;
    overflow-y: scroll;
    flex-grow: 1;
    
    @media (max-width: 768px) {
        padding: 0 15px;
        margin: 0;
    }
`;
