import React from "react";
import Aux from "../../components/Aux/Aux";
import PageTitle from "../../components/utility/text/PageTitle";
import DefaultHeader from "../../components/Headers/DefaultHeader";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import PageWidthContainer from "../../components/utility/containers/PageWidthContainer";
import Hero from "../../assets/images/background/FAQ.jpg"
import styled from "styled-components";
import Image from "../../components/Image/v1/Image";
import {AlwaysCard} from "../../components/utility/containers/Card";
import PageContainer from "../../components/utility/containers/PageContainer";
import {Paragraph} from "../../components/utility/helper/Paragraph";
import PageSubheader from "../../components/utility/text/PageSubheader";

const FAQ = (props) => {
    return (
        <Aux>
            <DefaultHeader />
            <PageWidthContainer
                width={100}
                height={ props.screenType === "Mobile" ? "250px" : "400px"}
                display={true}
            >
                <Image
                    cssUrl={Hero}
                    height={"100%"}
                />
                <Absolute>
                    <AlwaysCard>
                        <PageTitle margin={"none"} alignment={"center"}>Frequently Asked Questions</PageTitle>
                    </AlwaysCard>
                </Absolute>
            </PageWidthContainer>
            <PageContainer>
                <Section>
                    <Title>General</Title>
                    <Question>
                        Am I eligible to sign up?
                    </Question>
                    <Answer>
                        Eligibility depends on whether your building is enrolled in our service. Please check with your building management to confirm. If your community is not yet participating and you’re interested in our services, you can express your interest by submitting a request [here]!
                    </Answer>
                    <Question>
                        How does it work?
                    </Question>
                    <Answer>
                        Once your eligibility is confirmed, you’re just a few steps away from enjoying hassle-free laundry! Sign up [here], and we’ll deliver a hamper to your service address. Simply confirm receipt by scanning the QR code or entering the hamper code. Then, pack your hamper to the brim with laundry and leave it in the designated area on pickup days. The next day, your laundry will be returned, freshly washed and neatly folded, right to the same spot!
                    </Answer>
                    <Question>
                        What is the turnaround time?
                    </Question>
                    <Answer>
                        Enjoy the convenience of next-day service! Your laundry will be delivered back to you, freshly washed and perfectly folded, in just 24 hours.
                    </Answer>
                    <Question>
                        What are “Pick-Up” days?
                    </Question>
                    <Answer>
                        To seamlessly fit into your busy schedule and provide a reliable and consistent pick-up service, we operate on a pick-up day system. These are specific days when our drivers will come by to collect your hamper. For your convenience, please check with your property manager for your community’s designated pick-up days or refer to your account home page.
                    </Answer>
                    <Question>
                        Will I be notified?
                    </Question>
                    <Answer>
                        Absolutely! You’ll receive a text reminder the day before your designated pick-up day and a 1-hour time frame on the day of pick-up to ensure you’re prepared.
                    </Answer>
                    <Question>
                        Am I able to schedule an on-demand pick up?
                    </Question>
                    <Answer>
                        At this time, we do not offer an on-demand service.
                    </Answer>
                    <Question>
                        Do you offer dry cleaning?
                    </Question>
                    <Answer>
                        We do not offer a dry cleaning service at this time.
                    </Answer>
                    <Question>
                        Are there any contracts or long-term commitments?
                    </Question>
                    <Answer>
                        Nope! You can pause or cancel your subscription anytime on the accounts page—no long-term commitments.
                    </Answer>
                </Section>
                <Section>
                    <Title>Laundry</Title>
                    <Question>
                        What is your laundry process?
                    </Question>
                    <Answer>
                        We wash all clothes with cold water and dry them using low heat. This ensures a thorough clean while using the safest methods to protect your garments.
                    </Answer>
                    <Question>
                        Which products are used to wash my garments?
                    </Question>
                    <Answer>
                        We use an unscented, hypoallergenic detergent that’s free of dyes and perfumes to ensure a gentle but effective clean for your garments.
                    </Answer>
                    <Question>
                        Can I personalize how I have my laundry washed?
                    </Question>
                    <Answer>
                        We do not offer customization at this time.
                    </Answer>
                    <Question>
                        Can I have some of my clothes returned on a hanger?
                    </Question>
                    <Answer>
                        We do not offer hanging clothes. All clothes will be returned neatly folded.
                    </Answer>
                    <Question>
                        Will my clothes be washed with other customer’s clothes?
                    </Question>
                    <Answer>
                        Never! We will always wash and dry each hamper individually in separate machines.
                    </Answer>
                    <Question>
                        Who will wash my garments?
                    </Question>
                    <Answer>
                        Your garments are handled by our full-time professionals, who wash, dry, and ensure everything is properly cared for.
                    </Answer>
                    <Question>
                        Will you separate my white and colored garments or check if there are any items in pockets?
                    </Question>
                    <Answer>
                        No, we wash all loads exactly as they’re received in the hamper.
                    </Answer>
                    <Question>
                        Can I send bedding?
                    </Question>
                    <Answer>
                        Anything machine washable that fits in the hamper may be sent!
                    </Answer>
                    <Question>
                        Can I send shoes?
                    </Question>
                    <Answer>
                        Machine washable shoes are accepted.
                    </Answer>
                    <Question>
                        How will my garments be returned?
                    </Question>
                    <Answer>
                        Your garments will be neatly washed, folded, placed in polybags, and returned inside the hamper.
                    </Answer>
                </Section>
                <Section>
                    <PageSubheader>
                        For any additional questions, please contact us at <a href={"mailTo:info@theorigamicompany.com"}>info@theorigamicompany.com</a>
                    </PageSubheader>
                </Section>
            </PageContainer>
            <DefaultFooter isShortContent={props.isShortContent}/>
        </Aux>
    )
}

export default FAQ;

export const Question = styled.div`
    font-size: 18px;
    line-height: 16px;
    text-align: left;
    padding: 10px;
    background-color: #63B0C1;
    font-weight: bolder;

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 13px;
    }
`;

export const Answer = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    margin: 10px 0;
    padding: 10px;

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 13px;
    }
`;

const Section = styled.div`
    padding: 10px 100px;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const Title = styled.h2`
    font-weight: bolder;
`;

const Absolute = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
        width: 80%;
    }
`;
