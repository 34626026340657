import React, {useState} from "react";
import Aux from "../../components/Aux/Aux";
import {useDispatch, useSelector} from "react-redux";
import DefaultHeader from "../../components/Headers/DefaultHeader";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import PageWidthContainer from "../../components/utility/containers/PageWidthContainer";
import {Card, CardSmall, ImageCenter} from "../../components/utility/containers/Card";
import Button from "../../components/Form/Button/v1/Button";
import styled from "styled-components";
import FlexContainer from "../../components/utility/containers/FlexContainer";
import Input from "../../components/Form/Input/v1/Input";
import FormContainer from "../../components/Form/Container/v1/FormContainer";
import {signup} from "../../store/reducers/signup";
import Spinner from "../../components/Spinner/v1/Spinner";
import {changePassword, updateCustomerProfile} from "../../store/reducers/customer";
import {PasswordText, Text} from "../../components/utility/helper/Paragraph";
import PasswordStrength from "../../components/Signup/PasswordStrength";
import Back from "../../components/Back/Back";

const CustomerProfile = (props) => {
    const [loading, setLoading] = useState( false );
    const dispatch = useDispatch();
    const customerInfo = useSelector( (state) => state.customer );
    const [editProfile, setEditProfile] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [editPassword, setEditPassword] = useState(false);
    const [profileSuccess, setProfileSuccess] = useState("");
    const [addressSuccess, setAddressSuccess] = useState("");
    const [passwordSuccess, setPasswordSuccess] = useState("");
    const [profileError, setProfileError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [name, setName] = useState({
        firstname: customerInfo.firstname,
        lastname: customerInfo.lastname
    });
    const [address, setAddress] = useState({
        addressLine1: customerInfo.addressLine1,
        addressLine2: customerInfo.addressLine2,
        city: customerInfo.city,
        state: "TX",
        zipCode: customerInfo.zipCode,
        country: "USA"
    });
    const [password, setPassword] = useState({
        oldPassword: "",
        password: "",
        confirmPassword: ""
    });
    const [formError, setFormError] = useState({
        firstname: false,
        lastname: false,
        addressLine1: false,
        addressLine2: false,
        city: false,
        state: false,
        zipCode: false,
        oldPassword: false,
        password: false,
        confirmPassword: false
    });
    const validationRegex = {
        firstname: /^[A-Za-zÀ-ÖØ-öø-ÿ'’\-\s]+$/,
        lastname: /^[A-Za-zÀ-ÖØ-öø-ÿ'’\-\s]+$/,
        addressLine1: '',
        addressLine2: '',
        city: /^[A-Za-zÀ-ÖØ-öø-ÿ\'’\\-\\s\s]+$/,
        state: /^[A-Za-z]{2}$/,
        zipCode: /^\d{5}(-\d{4})?$/,
        oldPassword: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W]{8,}$/,
        password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W]{8,}$/,
        confirmPassword: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W]{8,}$/,
    };

    // Handler function for input changes
    const handleChange = (inpName, value) => {
        if( Object.keys(name).includes(inpName) ) {
            setName(prevState => ({
                ...prevState,
                [inpName]: value
            }));
        }
        else if ( Object.keys(address).includes(inpName) ) {
            setAddress(prevState => ({
                ...prevState,
                [inpName]: value
            }));
        }
        else if ( Object.keys(password).includes(inpName) ) {
            setPassword(prevState => ({
                ...prevState,
                [inpName]: value
            }));
        }
    };

    const setError = (inpName) => {
        setFormError(prevState => ({
            ...prevState,
            [inpName]: true
        }));
    }

    const clearError = (inpName) => {
        setFormError(prevState => ({
            ...prevState,
            [inpName]: false
        }));
    }

    const hasErrors = () => {
        let keys = [];
        let obj = {};
        if( editProfile ) {
            obj = name;
            keys = Object.keys( obj );
        }
        else if ( editAddress ) {
            obj = address;
            keys = Object.keys( obj );
        }
        else if ( editPassword ) {
            obj = password;
            keys = Object.keys( obj );
        }
        let error = false;
        for( let key of keys ) {
            error = error || formError[ key ];
            if( !obj[ key ] || ( validationRegex[key] && !validationRegex[ key ]?.test( obj[ key ]) ) ) {
                error = true;
                setFormError(prevState => ({
                    ...prevState,
                    [key]: true
                }));
            }
        }
        return error;
    }

    const handleClearErrors = () => {
        setFormError({
            firstname: false,
            lastname: false,
            addressLine1: false,
            addressLine2: false,
            city: false,
            state: false,
            zipCode: false,
            oldPassword: false,
            password: false,
            confirmPassword: false
        })
        setPasswordError("");
        setAddressError("");
        setProfileError("");
        setPasswordSuccess("");
        setAddressSuccess("");
        setProfileSuccess("");
    }

    const handleClosePasswordEdit = () => {
        setEditPassword( false );
        setPassword({
            oldPassword: "",
            password: "",
            confirmPassword: ""
        })
    }

    const handleCloseAddressEdit = () => {
        setEditAddress( false );
        setAddress(prevState => ({
            ...prevState,
            addressLine1: customerInfo.addressLine1,
            addressLine2: customerInfo.addressLine2,
            city: customerInfo.city,
            zipCode: customerInfo.zipCode,
        }))
    }

    const handleCloseProfileEdit = () => {
        setEditProfile( false );
        setName({
            firstname: customerInfo.firstname,
            lastname: customerInfo.lastname
        })
    }

    const handleUpdateProfile = () => {
        const profileUpdate= async () => {
            await dispatch( updateCustomerProfile({
                body: {
                    firstname: name.firstname,
                    lastname: name.lastname,
                    addressLine1: address.addressLine1,
                    addressLine2: address.addressLine2,
                    city: address.city,
                    state: address.state,
                    zipCode: address.zipCode,
                    country: address.country,
                },
                setLoading: setLoading,
                setError: editProfile ? setProfileError : setAddressError,
                setSuccess: editProfile ? setProfileSuccess : setAddressSuccess,
                editAddress: setEditAddress,
                editProfile: setEditProfile
            }))
        }

        setLoading(true);
        setTimeout(function() {
            profileUpdate()
        }, 10);
    }

    const handlePasswordChange = () => {
        const passwordChange = async () => {
            await dispatch( changePassword({
                body: {
                    currentPassword: password.oldPassword,
                    newPassword: password.password,
                },
                setLoading: setLoading,
                setError: setPasswordError,
                setSuccess: setPasswordSuccess,
                editPassword: setEditPassword,
            }))
        }

        setLoading(true);
        setTimeout(function() {
            passwordChange()
        }, 10);
    }

    const handleEditPassword = () => {
        handleCloseAddressEdit();
        handleCloseProfileEdit();
        handleClearErrors();

        setEditPassword( true );
    }

    const handleEditAddress = () => {
        handleCloseProfileEdit();
        handleClosePasswordEdit();
        handleClearErrors();

        setEditAddress( true );
    }

    const handleEditName = () => {
        handleClearErrors();
        handleCloseAddressEdit();
        handleClosePasswordEdit();

        setEditProfile( true );
    }

    let strength = 0;
    if( /[A-Za-z]/.test(password.password) ) {
        strength++;
    }
    if( /\d/.test(password.password) ) {
        strength++;
    }
    if( /^.{8,}$/.test(password.password) ) {
        strength++;
    }
    if( /[^A-Za-z0-9]/.test(password.password) ) {
        strength++;
    }

    return (
        <Aux>
            { loading && <Spinner /> }
            <DefaultHeader />
            <Back to={'/customer'} />
            <FlexContainer direction={props.screenType === "Desktop" ? "row" : "column"}>
                <PageWidthContainer
                    width={ props.screenType === "Desktop" ? 50 : 100 }
                    display={true}
                >
                    <MarginContainer>
                        <ImageCenter>
                            <Card>
                                <TableHeader>Profile Details</TableHeader>
                                <FormContainer errorMessage={profileError} successMessage={profileSuccess}>
                                    <Table>
                                        <TBody>
                                            <tr>
                                                <TD left={true}><TDContent>First Name</TDContent></TD>
                                                <TD right={true} align={"right"}>
                                                    { !editProfile && customerInfo.firstname}
                                                    { editProfile &&
                                                        <Input
                                                            name={"firstname"}
                                                            type={"text"}
                                                            profile={true}
                                                            regex={validationRegex.firstname}
                                                            screenType={props.screenType}
                                                            hasError={formError.firstname}
                                                            value={name.firstname}
                                                            setError={setError}
                                                            clearError={clearError}
                                                            onChange={handleChange}
                                                            errorMessage={"Please enter a valid First Name"}
                                                        />
                                                    }
                                                </TD>
                                            </tr>
                                            <tr>
                                                <TD left={true}><TDContent>Last Name</TDContent></TD>
                                                <TD right={true} align={"right"}>
                                                    { !editProfile && customerInfo.lastname }
                                                    { editProfile &&
                                                        <Input
                                                            name={"lastname"}
                                                            type={"text"}
                                                            profile={true}
                                                            regex={validationRegex.lastname}
                                                            screenType={props.screenType}
                                                            hasError={formError.lastname}
                                                            value={name.lastname}
                                                            setError={setError}
                                                            clearError={clearError}
                                                            onChange={handleChange}
                                                            errorMessage={"Please enter a valid Last Name"}
                                                        />
                                                    }
                                                </TD>
                                            </tr>
                                            <tr>
                                                <TD left={true}><TDContent>Email Address</TDContent></TD>
                                                <TD right={true}
                                                    align={"right"}>{customerInfo.emailAddress}</TD>
                                            </tr>
                                            <tr>
                                                <TD left={true}><TDContent>Phone Number</TDContent></TD>
                                                <TD right={true}
                                                    align={"right"}>{customerInfo.phoneNumber}</TD>
                                            </tr>
                                        </TBody>
                                    </Table>
                                    <ButtonParent>
                                        { !editProfile && <Button submit={ handleEditName }>Edit</Button> }
                                        { editProfile && <Button submit={ handleUpdateProfile } hasError={hasErrors}>Submit</Button> }
                                    </ButtonParent>
                                </FormContainer>
                            </Card>
                        </ImageCenter>
                    </MarginContainer>
                </PageWidthContainer>
                <PageWidthContainer
                    width={ props.screenType === "Desktop" ? 50 : 100 }
                    display={true}
                >
                    <MarginContainer>
                        <ImageCenter>
                            <Card>
                                <TableHeader>Address Details</TableHeader>
                                <FormContainer errorMessage={addressError} successMessage={addressSuccess}>
                                    <Table>
                                        <TBody>
                                            <tr>
                                                <TD left={true}><TDContent>Address</TDContent></TD>
                                                <TD right={true} align={"right"}>
                                                    { !editAddress && customerInfo.addressLine1}
                                                    { editAddress &&
                                                        <Input
                                                            name={"addressLine1"}
                                                            type={"text"}
                                                            profile={true}
                                                            regex={validationRegex.addressLine1}
                                                            screenType={props.screenType}
                                                            hasError={formError.addressLine1}
                                                            value={address.addressLine1}
                                                            setError={setError}
                                                            clearError={clearError}
                                                            onChange={handleChange}
                                                            errorMessage={"Please enter a valid Address"}
                                                        />
                                                    }
                                                </TD>
                                            </tr>
                                            <tr>
                                                <TD left={true}><TDContent>Unit</TDContent></TD>
                                                <TD right={true} align={"right"}>
                                                    { !editAddress && customerInfo.addressLine2}
                                                    { editAddress &&
                                                        <Input
                                                            name={"addressLine2"}
                                                            type={"text"}
                                                            profile={true}
                                                            regex={validationRegex.addressLine2}
                                                            screenType={props.screenType}
                                                            hasError={formError.addressLine2}
                                                            value={address.addressLine2}
                                                            setError={setError}
                                                            clearError={clearError}
                                                            onChange={handleChange}
                                                            errorMessage={"Please enter a valid Unit"}
                                                        />
                                                    }
                                                </TD>
                                            </tr>
                                            <tr>
                                                <TD left={true}><TDContent>City</TDContent></TD>
                                                <TD right={true} align={"right"}>
                                                    { !editAddress && customerInfo.city}
                                                    { editAddress &&
                                                        <Input
                                                            name={"city"}
                                                            type={"text"}
                                                            profile={true}
                                                            regex={validationRegex.city}
                                                            screenType={props.screenType}
                                                            hasError={formError.city}
                                                            value={address.city}
                                                            setError={setError}
                                                            clearError={clearError}
                                                            onChange={handleChange}
                                                            errorMessage={"Please enter a valid City"}
                                                        />
                                                    }
                                                </TD>
                                            </tr>
                                            <tr>
                                                <TD left={true}><TDContent>State</TDContent></TD>
                                                <TD right={true}
                                                    align={"right"}>{customerInfo.state}</TD>
                                            </tr>
                                            <tr>
                                                <TD left={true}><TDContent>Country</TDContent></TD>
                                                <TD right={true}
                                                    align={"right"}>{customerInfo.country}</TD>
                                            </tr>
                                            <tr>
                                                <TD left={true}><TDContent>Zip Code</TDContent></TD>
                                                <TD right={true} align={"right"}>
                                                    { !editAddress && customerInfo.zipCode}
                                                    { editAddress &&
                                                        <Input
                                                            name={"zipCode"}
                                                            type={"number"}
                                                            profile={true}
                                                            regex={validationRegex.zipCode}
                                                            screenType={props.screenType}
                                                            hasError={formError.zipCode}
                                                            value={address.zipCode}
                                                            setError={setError}
                                                            clearError={clearError}
                                                            onChange={handleChange}
                                                            errorMessage={"Please enter a valid Zip Code"}
                                                        />
                                                    }
                                                </TD>
                                            </tr>
                                        </TBody>
                                    </Table>
                                    <ButtonParent>
                                        { !editAddress && <Button submit={ handleEditAddress }>Edit</Button> }
                                        { editAddress && <Button submit={ handleUpdateProfile } hasErrors={hasErrors}>Submit</Button> }
                                    </ButtonParent>
                                </FormContainer>
                            </Card>
                        </ImageCenter>
                    </MarginContainer>
                </PageWidthContainer>
                <PageWidthContainer
                    width={ props.screenType === "Desktop" ? 50 : 100 }
                    display={true}
                >
                    <MarginContainer>
                        <ImageCenter>
                            <Card>
                                <TableHeader>Update Password</TableHeader>
                                <FormContainer errorMessage={passwordError} successMessage={passwordSuccess}>
                                    <Table>
                                        <TBody>
                                            <tr>
                                                <TD left={true}><TDContent>Current Password</TDContent></TD>
                                                <TD right={true} align={"right"}>
                                                    { !editPassword && "********"}
                                                    { editPassword &&
                                                        <Input
                                                            name={"oldPassword"}
                                                            type={"password"}
                                                            profile={true}
                                                            regex={validationRegex.oldPassword}
                                                            screenType={props.screenType}
                                                            hasError={formError.oldPassword}
                                                            value={password.oldPassword}
                                                            setError={setError}
                                                            clearError={clearError}
                                                            onChange={handleChange}
                                                            errorMessage={"Please enter a valid password"}
                                                        />
                                                    }
                                                </TD>
                                            </tr>
                                            <tr>
                                                <TD left={true}><TDContent>New Password</TDContent></TD>
                                                <TD right={true} align={"right"}>
                                                    { !editPassword && "********"}
                                                    { editPassword &&
                                                        <Input
                                                            name={"password"}
                                                            type={"password"}
                                                            profile={true}
                                                            regex={validationRegex.password}
                                                            screenType={props.screenType}
                                                            hasError={formError.password}
                                                            value={password.password}
                                                            setError={setError}
                                                            clearError={clearError}
                                                            onChange={handleChange}
                                                            errorMessage={"Please enter a valid password"}
                                                        />
                                                    }
                                                </TD>
                                            </tr>
                                            <tr>
                                                <TD left={true}><TDContent>Confirm Password</TDContent></TD>
                                                <TD right={true} align={"right"}>
                                                    { !editPassword && "********"}
                                                    { editPassword &&
                                                        <Input
                                                            name={"confirmPassword"}
                                                            type={"password"}
                                                            profile={true}
                                                            regex={validationRegex.confirmPassword}
                                                            screenType={props.screenType}
                                                            hasError={formError.confirmPassword}
                                                            value={password.confirmPassword}
                                                            setError={setError}
                                                            clearError={clearError}
                                                            onChange={handleChange}
                                                            errorMessage={"Please enter a valid password"}
                                                        />
                                                    }
                                                </TD>
                                            </tr>
                                        </TBody>
                                    </Table>
                                    { editPassword &&
                                        <StrengthContainer>
                                            <PasswordText><Text>Ensure your password is at least 8 characters long and includes a mix of letters, numbers, and special characters for better security</Text></PasswordText>
                                            <PasswordStrength strengthNumber={strength}/>
                                        </StrengthContainer>
                                    }
                                    <ButtonParent>
                                        { !editPassword && <Button submit={ handleEditPassword }>Update</Button> }
                                        { editPassword && <Button submit={ handlePasswordChange } hasErrors={hasErrors}>Submit</Button> }
                                    </ButtonParent>
                                </FormContainer>
                            </Card>
                        </ImageCenter>
                    </MarginContainer>
                </PageWidthContainer>
            </FlexContainer>
            <DefaultFooter isShortContent={props.isShortContent} />
        </Aux>
    )
}

export default CustomerProfile;

const StrengthContainer = styled.div`
`;

const ButtonParent = styled.div`
    width: 100%;
`;

const MarginContainer = styled.div`
    margin: 30px;
`;

const TDContent = styled.div`
    display: flex;
    align-items: center;
`;

const TableHeader = styled.div`
    margin: 10px 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
`;

const Table = styled.table`
    width: 100%;
`;

const TBody = styled.tbody`
`;

const TD = styled.td`
    text-align: ${props => props.align || "left"};
    padding: 10px 15px;
    font-size: 14px;
    font-weight: ${props => props.left ? 300 : 700};
    font-style: italic;
    line-height: 18px;
`;
