import { createSlice } from '@reduxjs/toolkit';

export const customerSlice = createSlice({
    name: 'customer',
    initialState: {
        firstname: "",
        lastname: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        phoneNumber: "",
        isPhoneNumberVerified: false,
        showPhoneSuccess: false,
        emailAddress: "",
        isEmailVerified: false,
        showEmailSuccess: false,
        serviceSchedule: {},
        hampers: [{
            hamperCode: "",
            isVerified: false
        }],
        subscriptions: [],
        latestInvoice: {},
    },
    reducers: {
        updateDetails: (state, action) => {
            state.firstname = action.payload.firstname;
            state.lastname = action.payload.lastname;
            state.addressLine1 = action.payload.addressLine1;
            state.addressLine2 = action.payload.addressLine2;
            state.city = action.payload.city;
            state.state = action.payload.state;
            state.zipCode = action.payload.zipCode;
            state.country = action.payload.country;
            state.phoneNumber = action.payload.phoneNumber;
            state.isPhoneNumberVerified = action.payload.isPhoneNumberVerified;
            state.emailAddress = action.payload.emailAddress;
            state.isEmailVerified = action.payload.isEmailVerified;
            state.serviceSchedule = action.payload.serviceSchedule;
            state.hampers = action.payload.hampers;
            state.subscriptions = action.payload.subscriptions;
            state.latestInvoice = action.payload.latestInvoice;
        },
        updateCustomerDetails: (state, action) => {
            state.firstname = action.payload.firstname;
            state.lastname = action.payload.lastname;
            state.addressLine1 = action.payload.addressLine1;
            state.addressLine2 = action.payload.addressLine2;
            state.city = action.payload.city;
            state.state = action.payload.state;
            state.zipCode = action.payload.zipCode;
            state.country = action.payload.country;
        },
        clearCustomerDetails: (state) => {
            state.firstname = "";
            state.lastname = "";
            state.addressLine1 = "";
            state.addressLine2 = "";
            state.city = "";
            state.state = "";
            state.zipCode = "";
            state.country = "";
            state.phoneNumber = "";
            state.isPhoneNumberVerified = false;
            state.emailAddress = "";
            state.isEmailVerified = false;
            state.serviceSchedule = {};
            state.hampers = [];
            state.subscriptions = [];
            state.latestInvoice = {};
        },
        setEmailVerified: (state, action) => {
            state.isEmailVerified = action.payload.isEmailVerified;
            state.showEmailSuccess = true;
        },
        removeEmailSuccess: (state) => {
            state.showEmailSuccess = false;
        },
        setPhoneVerified: (state, action) => {
            state.isPhoneNumberVerified = action.payload.isPhoneNumberVerified;
            state.showPhoneSuccess = true;
        },
        removePhoneSuccess: (state) => {
            state.showPhoneSuccess = false;
        },
        updateCustomerHamperVerification: (state, action) => {
            state.hampers.forEach( (hamper, index) => {
                if( hamper.hamperCode === action.payload.hamperCode ) {
                    state.hampers[ index ] = {
                        id: hamper.id,
                        hamperCode: hamper.hamperCode,
                        isVerified: true
                    }
                }
            })
        },
        updateSubscriptions: (state, action) => {
            state.subscriptions = action.payload.subscriptions;
        },
        updateCustomerProfile: () => {},
        changePassword: () => {},
        getCustomerDetails: () => {},
        sendEmailVerification: () => {},
        sendPhoneVerification: () => {},
        verifyEmailCode: () => {},
        verifyPhoneCode: () => {}
    },
})

export const {
    updateDetails,
    updateCustomerDetails,
    getCustomerDetails,
    clearCustomerDetails,
    setEmailVerified,
    setPhoneVerified,
    sendEmailVerification,
    sendPhoneVerification,
    updateCustomerHamperVerification,
    updateSubscriptions,
    verifyEmailCode,
    verifyPhoneCode,
    removeEmailSuccess,
    removePhoneSuccess,
    updateCustomerProfile,
    changePassword
} = customerSlice.actions;

export default customerSlice.reducer;
