import React from "react";
import Aux from "../../components/Aux/Aux";
import PageTitle from "../../components/utility/text/PageTitle";
import DefaultHeader from "../../components/Headers/DefaultHeader";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import PageWidthContainer from "../../components/utility/containers/PageWidthContainer";
import Hero from "../../assets/images/background/Home_Hero.png"
import Vision from "../../assets/images/background/Vison.jpg"
import styled from "styled-components";
import PageSubheader from "../../components/utility/text/PageSubheader";
import Image from "../../components/Image/v1/Image";
import Button from "../../components/Form/Button/v1/Button";
import {useNavigate} from "react-router-dom";
import PageContainer from "../../components/utility/containers/PageContainer";

const CompanyHome = (props) => {
    const navigate = useNavigate();

    const toSignup = () => {
        navigate("/signup");
    }
    return (
        <Aux>
            <DefaultHeader />
            <PageWidthContainer
                width={100}
                height={ "92vh" }
                display={true}
            >
                <Overlay />
                <Image
                    cssUrl={Hero}
                    height={"100%"}
                />
                <Absolute>
                    <HomeHeroCard>
                        <PageTitle margin={"none"} alignment={"center"}>Remarkably Cleaner.<br />Amazingly Simpler.</PageTitle>
                        <PageSubheader>
                            Providing fast and reliable laundry services so that you have time for what matters most.
                        </PageSubheader>
                        <Button white={true} submit={toSignup}>Sign Up</Button>
                    </HomeHeroCard>
                </Absolute>
            </PageWidthContainer>
                <ColumnParent>
                    <Column>
                        <PaddingRight>
                            <Title>
                                Vision
                            </Title>
                            <PageSubheader>
                                Transforming the way people experience laundry
                            </PageSubheader>
                            <PageSubheader>
                                With our unmatched speed, innovation, and reliability as the top laundry service provider, we make laundry a hassle free experience.
                            </PageSubheader>
                        </PaddingRight>
                    </Column>
                    { props.screenType !== "Mobile" &&
                        <Column>
                            <Image
                                cssUrl={Vision}
                            />
                        </Column>
                    }
                </ColumnParent>
                <BackgroundTeal>
                    <Padding>
                        <Title>
                            About Us
                        </Title>
                        <PageSubheader>
                            The Origami Company is a dedicated provider of wash and fold service solutions tailored specifically for multi-family communities. We focus on delivering efficient, reliable, and convenient laundry services, ensuring that residents and partners can enjoy a seamless and stress-free experience.
                        </PageSubheader>
                        <PageSubheader>
                            With our unmatched speed, innovation, and reliability as the top laundry service provider, we make laundry a hassle free experience.
                        </PageSubheader>
                    </Padding>
                </BackgroundTeal>
            <DefaultFooter isShortContent={props.isShortContent} />
        </Aux>
    )
}

export default CompanyHome;

const BackgroundTeal = styled.div`
    background-color: #63B0C1;
    padding: 15px 50px;
    color: black !important;

    & p {
        color: black !important;
        font-size: 24px;
        margin: 15px 0;
    }
`;

const Padding = styled.div`
    padding: 10px;
`;

const PaddingRight = styled.div`
    padding-right: 150px;

    @media (max-width: 768px) {
        padding-right: 0;
    }
`;

const Title = styled.h1`
    font-weight: bolder;
    font-size: 2rem;
    text-decoration: underline;
`;

const ColumnParent = styled.div`
    display: flex;
    flex-direction: row;
    padding: 15px 50px;
`;

const Column = styled.div`
    width: 50%;

    & p {
        font-size: 24px;
        margin: 15px 0;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #030303;
    opacity: .5;
`;

const Absolute = styled.div`
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-100%, -50%);
    color: #ffffff !important;

    & h1 {
        font-size: 3rem;
        text-align: right;
    }

    & p {
        color: #fff !important;
        font-size: 1.5rem;
        text-align: right;
        line-height: 1.5rem;
    }

    @media (max-width: 768px) {
        left: 0;
        transform: translateY(-50%);
        margin: 10px;

        & h1 {
            font-size: 2rem;
            text-align: center;
        }

        & p {
            text-align: center;
        }
    }
`;

export const HomeHeroCard = styled.div`
    width: 600px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 100%;
        padding: 30px;
    }
`;
