import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';
import authReducer from "../reducers/auth";
import hamperReducer from "../reducers/hamper";
import {rootSagas} from "./index";
import signupReducer from "../reducers/signup";
import customerReducer from "../reducers/customer";
import trackingRouter from "../reducers/tracking";
import employeeRouter from "../reducers/employee";

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Configure the store
const store = configureStore({
    reducer: {
        auth: authReducer,
        hamper: hamperReducer,
        signup: signupReducer,
        customer: customerReducer,
        tracking: trackingRouter,
        employee: employeeRouter
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
})

// Run the root saga
sagaMiddleware.run(rootSagas)

export default store