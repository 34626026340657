import React from "react";
import Aux from "../../Aux/Aux";
import {useSelector} from "react-redux";
import PageTitle from "../../utility/text/PageTitle";
import FormContainer from "../../Form/Container/v1/FormContainer";
import styled from "styled-components";
import {Card, CardParent} from "../../utility/containers/Card";
import PageSubheader from "../../utility/text/PageSubheader";
import Verification from "./Verification";

const HamperVerification = (props) => {
    const error = useSelector( (state) => state.hamper.error );
    const successMessage = useSelector( (state) => state.hamper.successMessage );

    return (
        <Aux>
            <CardParent>
                <Card>
                    <UpdatePageTitle>
                        <PageTitle>Verify Your Hamper</PageTitle>
                    </UpdatePageTitle>
                    <PageSubheader>Please enter your hamper code or scan the QR code to verify your hamper.</PageSubheader>
                    <FormContainer errorMessage={error} successMessage={successMessage}>
                        {/*<Label>Scan QR Code</Label>*/}
                        {/*<QRCodeScanner handleScanResult={handleScanResult}/>*/}
                        <Verification
                            setLoading={props.setLoading}
                            screenType={props.screenType}
                        />
                    </FormContainer>
                </Card>
            </CardParent>
        </Aux>
    )
}

export default HamperVerification;

const UpdatePageTitle = styled.div`
    & h1 {
        margin: 0 !important;
    }
`;
