import React, {useState} from "react";
import Aux from "../../components/Aux/Aux";
import {useSelector} from "react-redux";
import PageContainer from "../../components/utility/containers/PageContainer";
import Spinner from "../../components/Spinner/v1/Spinner";
import DefaultHeader from "../../components/Headers/DefaultHeader";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import HamperShipped from "../../components/Verification/HamperVerification/HamperShipped";
import HamperVerification from "../../components/Verification/HamperVerification/HamperVerification";

const HamperValidate = (props) => {
    const hamperInfo = useSelector( (state) => state.customer.hampers );
    const [loading, setLoading] = useState( false );
    const [acknowledged, setAcknowledged] = useState( false );

    const handleAcknowledgement = () => {
        setAcknowledged( true );
    }

    if( hamperInfo?.[0]?.isVerified ) {
        props.success();
    }

    return (
        <Aux>
            {loading && <Spinner />}
            <DefaultHeader isLoading={setLoading}/>
            <PageContainer>
                { !acknowledged && <HamperShipped acknowledge={handleAcknowledgement} /> }
                { acknowledged && <HamperVerification screenType={props.screenType} setLoading={setLoading} /> }
            </PageContainer>
            <DefaultFooter isShortContent={props.isShortContent} />
        </Aux>
    )
}

export default HamperValidate;
