import React, {useState} from "react";
import styled from "styled-components"
import Aux from "../Aux/Aux";

const PasswordStrength = (props) => {
    let strength;

    switch (props.strengthNumber) {
        case 1:
            strength = "Weak";
            break;
        case 2:
            strength = "Weak";
            break;
        case 3:
            strength = "Average";
            break;
        case 4:
            strength = "Strong";
            break;
        default:
            strength = "";
    }

    return (
        <Aux>
            <StrengthContainer>
                <StrengthText>Strength</StrengthText>
                <StrengthBar
                    strengthNumber={props.strengthNumber}
                />
                <StrengthText>{strength}</StrengthText>
            </StrengthContainer>
        </Aux>
    )
}

export default PasswordStrength;

const StrengthContainer = styled.div`
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StrengthText = styled.div`
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    width: 430px;

    @media (max-width: 768px) {
        width: 90%;
        font-size: 12px;
        line-height: 14px;
    }
`;

const StrengthBar = styled.div`
    width: 430px;
    height: 10px;
    background-color: #d3d3d3;
    border-radius: 10px;
    
    &:before {
        content: '';
        display: block;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: ${props => props.strengthNumber === 4 ? "10px" : 0};
        border-top-right-radius: ${props => props.strengthNumber === 4 ? "10px" : 0};
        width: ${props => props.strengthNumber ? `calc((${props.strengthNumber} / 4) * 430px)` : 0};
        height: 10px;
        background-color: ${props => props.strengthNumber === 4 ? "#4cd964" : ( props.strengthNumber === 3 ? "#FFCC00" : "#ff0000" ) };
    }


    @media (max-width: 768px) {
        width: 90%;

        &:before {
            width: ${props => props.strengthNumber ? `calc((${props.strengthNumber} / 4) * 100%)` : 0};
        }
    }
`;
