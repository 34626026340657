import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import SubdomainRouter from "./routes/SubdomainRouter/v1/SubdomainRouter";
import "./index.css"
import {checkAuthentication} from "./store/reducers/auth";
import Aux from "./components/Aux/Aux";
import Spinner from "./components/Spinner/v1/Spinner";

const App = () => {
    const dispatch = useDispatch(),
        [loading, setLoading] = useState(true);

    useEffect(() => {
        const validateAuth = async () => {
            await dispatch( checkAuthentication() );
            setLoading( false );
        }
        validateAuth().catch(err => { setLoading( false )});
    }, [dispatch]);

    return (
        <Aux>
            { loading && <Spinner /> }
            <SubdomainRouter pageLoading={loading} />
        </Aux>
    )
}

export default App;
