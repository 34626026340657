import React from "react";
import SVG from "./SVG";

const Subscribed = (props) => {
    return (
        <SVG viewBox="0 0 24.356435643564396 24" width="24.356435643564396" height="24" fill="none">
            <rect></rect>
            <g opacity="100%">
                <rect x="0" y="0" width="24.356435643564357" height="24" fill="#4cd964" rx="12"
                      filter="url(#filter_dshadow_0_0_0_00000014)"></rect>
            </g>
            <svg viewBox="0 0 448 512" width="12" height="12" fill="#fff"
                 x="6.08910891089109" y="6" opacity="100%">
                <path
                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
            </svg>
            <defs>
                <filter id="filter_dshadow_0_0_0_00000014" color-interpolation-filters="sRGB"
                        filterUnits="userSpaceOnUse">
                    <feFlood flood-opacity="0" result="bg-fix"></feFlood>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="alpha"></feColorMatrix>
                    <feOffset dx="0" dy="0"></feOffset>
                    <feGaussianBlur stdDeviation="0"></feGaussianBlur>
                    <feComposite in2="alpha" operator="out"></feComposite>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"></feColorMatrix>
                    <feBlend mode="normal" in2="bg-fix" result="bg-fix-filter_dshadow_0_0_0_00000014"></feBlend>
                    <feBlend in="SourceGraphic" in2="bg-fix-filter_dshadow_0_0_0_00000014" result="shape"></feBlend>
                </filter>
            </defs>
        </SVG>)
}

export default Subscribed;
