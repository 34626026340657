import React from "react";
import styled from "styled-components";

const SVG = (props) => {
    return (
        <SVGStyles viewBox={props.viewBox}>
            {props.children}
        </SVGStyles>)
}

export default SVG;

const SVGStyles = styled.svg`
    color: #929292;
    fill: #929292;
    font-size: 14px;
    width: 14px;
    height: 16px;
`
