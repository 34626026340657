import React, {useState} from "react";
import Aux from "../../components/Aux/Aux";
import Spinner from "../../components/Spinner/v1/Spinner";
import PageWidthContainer from "../../components/utility/containers/PageWidthContainer";
import Image from "../../components/Image/v1/Image";
import SignupImg from "../../assets/images/background/Signup_page.png"
import AddressImg from "../../assets/images/background/Address.png"
import PasswordImg from "../../assets/images/background/Password.png"
import FlexContainer from "../../components/utility/containers/FlexContainer";
import DefaultHeader from "../../components/Headers/DefaultHeader";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import SignupInfo from "../../components/Signup/SignupInfo";
import AddressInfo from "../../components/Signup/AddressInfo";
import PasswordInfo from "../../components/Signup/PasswordInfo";
import {addressValidation, signup, usernameValidation} from "../../store/reducers/signup";
import {useDispatch} from "react-redux";

const CompanySignUp = (props) => {
    const [isLoading, setIsLoading] = useState(false),
        [imageLeft, setImageLeft] = useState(false),
        dispatch = useDispatch(),
        [step, setStep] = useState(1),
        [formData, setFormData] = useState({
            firstname: '',
            lastname: '',
            emailAddress: '',
            phoneNumber: '',
            emailAddressConsent: true,
            phoneNumberConsent: true,
            password: '',
            confirmPassword: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: 'TX',
            zipCode: '',
            country: 'USA',
        });

    // Handler function for input changes
    const handleChange = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        const customerSignup = async () => {
            await dispatch( signup({
                body: formData,
                setLoading: setIsLoading
            }) )
        }

        setIsLoading(true);
        setTimeout(function() {
            customerSignup()
        }, 10);
    }

    const handleNext = () => {
        setIsLoading( true );
        setTimeout( function() {
            if( step === 1 ) {
                const validateUsername = async () => {
                    await dispatch( usernameValidation({
                        body: {
                            emailAddress: formData[ "emailAddress" ],
                            phoneNumber: formData[ "phoneNumber" ],
                        },
                        loading: setIsLoading,
                        setStep: setStep,
                        nextStep: (step + 1),
                        setImageLeft: setImageLeft
                    }) )
                }

                validateUsername();
            }
            else if( step === 2 ) {
                const validateAddress = async () => {
                    await dispatch( addressValidation({
                        body: {
                            addressLine1: formData[ "addressLine1" ],
                            addressLine2: formData[ "addressLine2" ],
                            city: formData[ "city" ],
                            state: formData[ "state" ],
                            zipCode: formData[ "zipCode" ],
                            country: formData[ "country" ]
                        },
                        loading: setIsLoading,
                        setStep: setStep,
                        nextStep: (step + 1),
                        setImageLeft: setImageLeft
                    }) )
                }

                validateAddress();
            }
            else {
                if( (step + 1) % 2 === 0 ) {
                    setImageLeft( true );
                }
                else {
                    setImageLeft( false );
                }
                setStep( step + 1 );
                setIsLoading( false );
            }
        }, 500)
    }

    const handleBack = () => {
        setIsLoading( true );
        setTimeout( function() {
            if( (step - 1) % 2 === 0 ) {
                setImageLeft( true );
            }
            else {
                setImageLeft( false );
            }

            setStep( step - 1 );
            setIsLoading( false );
        }, 500)
    }

    return (
        <Aux>
            {isLoading && <Spinner />}
            <FlexContainer>
                { imageLeft && props.screenType === "Desktop" &&
                    <PageWidthContainer
                        width={33.33}
                        display={true}
                    >
                        <Image url={AddressImg} />
                    </PageWidthContainer>
                }
                <PageWidthContainer
                    width={props.screenType === "Desktop" ? 66.66 : 100}
                    display={true}
                    id={"form-content"}
                >
                    <DefaultHeader />
                    { step === 1 && <SignupInfo
                        screenType={ props.screenType }
                        isLoading={setIsLoading}
                        formData={formData}
                        handleChange={handleChange}
                        handleNext={handleNext}
                    /> }
                    { step === 2 && <AddressInfo
                        screenType={ props.screenType }
                        isLoading={setIsLoading}
                        formData={formData}
                        handleChange={handleChange}
                        handleNext={handleNext}
                        handleBack={handleBack}
                    /> }
                    { step === 3 && <PasswordInfo
                        screenType={ props.screenType }
                        isLoading={setIsLoading}
                        formData={formData}
                        handleChange={handleChange}
                        handleNext={handleSubmit}
                        handleBack={handleBack}
                    /> }
                    <DefaultFooter />
                </PageWidthContainer>
                { !imageLeft && props.screenType === "Desktop" &&
                    <PageWidthContainer
                        width={33.33}
                        display={true}
                    >
                        { step === 1 && <Image url={SignupImg} /> }
                        { step === 3 && <Image url={PasswordImg} /> }
                    </PageWidthContainer>
                }
            </FlexContainer>
        </Aux>
    )
}

export default CompanySignUp;
