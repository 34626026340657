import {put} from "redux-saga/effects";
import {login, logout, updateAuth} from "../reducers/auth";

export function* authCheckAuthenticationSaga( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_REFRESH_AUTH, {
            method: 'POST',
            credentials: 'include'
        })

        const data = yield postResponse.json();

        if( !postResponse.ok ) {
            const errorMessage = data.message || "Unexpected Error Occurred";
            throw Error(errorMessage);
        }
        yield put(updateAuth({
            "role": data["role"],
            "isAuthenticated": true,
            "error": ""
        }));
    }
    catch (e) {
        yield authLogoutUser();
    }
}

export function* authLoginUser( action ) {
    try{
        const postResponse = yield fetch(process.env.REACT_APP_AUTH, {
            method: 'POST',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify( action.payload.body )
        })
        const data = yield postResponse.json();

        if( !postResponse.ok ) {
            const errorMessage = data.message || "Unexpected Error Occurred";
            throw Error(errorMessage);
        }

        // const isAuthenticated = true;
        yield put(login({
            "role": data["role"],
        } ));
    }
    catch (e) {
        const error = "The Username and Password provided do not match"
        yield put(updateAuth({
            error,
            "role": "",
            "isAuthenticated": false
        } ));
    }
    yield action.payload.setLoading( false );
}

export function* authLogoutUser( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_LOGOUT, {
            method: 'POST',
            credentials: 'include'
        })

        if( !postResponse.ok ) {
            const data = yield postResponse.json();
            const errorMessage = data.message || "Unexpected Error Occurred";
            throw Error(errorMessage);
        }

        yield put( logout() )
    }
    catch (e) {
        yield updateAuth( {errorMessage: e.toString() } )
    }
}