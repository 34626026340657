import { takeEvery } from "redux-saga/effects";
import {authCheckAuthenticationSaga, authLoginUser, authLogoutUser} from "./auth";
import {checkAuthentication, handleLogin, handleLogout} from "../reducers/auth"
import {tracking, verify} from "../reducers/hamper";
import {hamperTrackingUpdate, hamperVerify} from "./hamper";
import {addressValidation, signup, usernameValidation} from "../reducers/signup";
import {handleAddressValidation, handleUsernameValidation, signupCustomer} from "./signup";
import {
    changePassword,
    getCustomerDetails,
    sendEmailVerification,
    sendPhoneVerification, updateCustomerProfile,
    verifyEmailCode,
    verifyPhoneCode
} from "../reducers/customer";
import {
    handleChangePassword,
    handleCustomerDetails,
    handleSendEmailVerification,
    handleSendPhoneVerification, handleUpdateCustomerInfo,
    handleVerifyEmail, handleVerifyPhone
} from "./customer";
import {getTrackingDetails} from "../reducers/tracking";
import {handleTrackingDetailsCall} from "./tracking";
import {addressSearch} from "../reducers/employee";
import {employeeAddressSearch} from "./employee";

export function* rootSagas() {
    yield takeEvery(checkAuthentication.type, authCheckAuthenticationSaga)
    yield takeEvery(handleLogin.type, authLoginUser)
    yield takeEvery(handleLogout.type, authLogoutUser)

    yield takeEvery(tracking.type, hamperTrackingUpdate)

    yield takeEvery(signup.type, signupCustomer)
    yield takeEvery(addressValidation.type, handleAddressValidation)
    yield takeEvery(usernameValidation.type, handleUsernameValidation)

    yield takeEvery(getCustomerDetails.type, handleCustomerDetails)
    yield takeEvery(sendEmailVerification.type, handleSendEmailVerification)
    yield takeEvery(sendPhoneVerification.type, handleSendPhoneVerification)
    yield takeEvery(verifyEmailCode.type, handleVerifyEmail)
    yield takeEvery(verifyPhoneCode.type, handleVerifyPhone)
    yield takeEvery(verify.type, hamperVerify);
    yield takeEvery(updateCustomerProfile.type, handleUpdateCustomerInfo)
    yield takeEvery(changePassword.type, handleChangePassword)

    yield takeEvery(getTrackingDetails.type, handleTrackingDetailsCall)

    yield takeEvery(addressSearch.type, employeeAddressSearch);
}