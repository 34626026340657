import {updateTrackingDetails, updateTrackingError} from "../reducers/tracking";
import {put} from "redux-saga/effects";

export function* handleTrackingDetailsCall( action ) {
    try {
        console.log( process.env.REACT_APP_CUSTOMER_CHECKOUT_PAYMENT );
        const postResponse = yield fetch(process.env.REACT_APP_HAMPER_STATUS.replace("{hamperCode}", action.payload.hamperCode?.toUpperCase()), {
            method: 'GET',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            }
        })

        const data = yield postResponse.json();

        if (!postResponse.ok) {
            const errorMessage = data.message || "Something went wrong when attempting to get tracking details for hamper code " + action.payload.hamperCode;
            throw Error(errorMessage);
        }
        yield put( updateTrackingDetails( data ))
        yield put( updateTrackingError( {error: ""} ) );
    }
    catch (e) {
        const error = e.toString();
        yield put( updateTrackingError( {error} ) );
    }
    yield action.payload.setLoading( false );
}
