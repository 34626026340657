import { createSlice } from '@reduxjs/toolkit';

export const hamperSlice = createSlice({
    name: 'hamper',
    initialState: {
        successMessage: "",
        error: "",
    },
    reducers: {
        updateHamper: (state, action) => {
            state.error = action.payload.error;
            state.successMessage = action.payload.successMessage;
        },
        tracking: () => {},
        verify: () => {},
    },
})

export const {
    updateHamper,
    tracking,
    verify
} = hamperSlice.actions;

export default hamperSlice.reducer;
