import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components"
import {useDispatch, useSelector} from "react-redux";
import Aux from "../../Aux/Aux";
import FormContainer from "../../Form/Container/v1/FormContainer";
import Input from "../../Form/Input/v1/Input";
import {Paragraph, Text} from "../../utility/helper/Paragraph";

const Verification = (props) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        [props.inputName]: ''
    });
    const [formError, setFormError] = useState({
        [props.inputName]: false
    });
    const inputRef = useRef();
    const isVerified = useSelector( (state) => state.customer[props.isVerified] );
    const [isVerificationSent, setIsVerificationSent] = useState(false);
    const [verificationError, setVerificationError] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");
    const [codeIndex, setCodeIndex] = useState(-1);
    //
    // const handleChange = (name, value) => {
    //     setFormData(prevState => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    //
    //     if( /^\d{6}$/.test( value ) ) {
    //         handleVerification( value )
    //     }
    // }

    const setError = (name) => {
        setFormError(prevState => ({
            ...prevState,
            [name]: true
        }));
    }

    const clearError = (name) => {
        setFormError(prevState => ({
            ...prevState,
            [name]: false
        }));
    }

    const handleSendVerification = ( skipMessage ) => {
        const sendCode = async () => {
            await dispatch( props.sendVerification({
                "setLoading": props.setLoading,
                "verificationSent": setIsVerificationSent,
                "setSuccess": skipMessage === true ? (() => {}) : setInfoMessage,
                "setError": setVerificationError,
                "setIndex": setCodeIndex,
                "setInput": setFormData,
            } ) );
        }

        // setFormData(prevState => ({
        //     ...prevState,
        //     [ props.inputName ]: prevState[ props.inputName ].substring(0, 0)
        // }));
        // setCodeIndex( -1 );
        props.setLoading( true );
        sendCode();
    }

    const handleVerification = (value) => {
        const verify = async () => {
            await dispatch( props.validateCode({
                "setLoading": props.setLoading,
                "setError": setVerificationError,
                "setSuccess": setInfoMessage,
                "setIndex": setCodeIndex,
                "setInput": setFormData,
                "body": { verificationCode: value.toUpperCase() }
            } ) );
        }

        props.setLoading( true );
        verify();
    }

    const handleKeyPress = (event) => {
        if (event.key.length === 1 && (
            (event.key >= 'a' && event.key <= 'z') ||
            (event.key >= 'A' && event.key <= 'Z') ||
            (event.key >= '0' && event.key <= '9')) ) {
            let data = "";
            setFormData(prevState => {
                data = prevState[ props.inputName ] + event.key;

                if( /^\d{6}$/.test( data ) ) {
                    handleVerification( data )
                }

                setCodeIndex( prevState => {
                    return data.length
                });

                return {
                    ...prevState,
                    [ props.inputName ]: data
                }
            });
        }
        else if( event.key === 'Backspace' ) {
            setFormData(prevState => ({
                ...prevState,
                [ props.inputName ]: prevState[ props.inputName ].substring(0, prevState[ props.inputName ].length - 1)
            }));
            setCodeIndex( prevState => {
                if( prevState > 0 ) {
                    return (prevState - 1)
                }
                return prevState
            });
        }
    };

    const handleClick = () => {
        let idx = formData[ props.inputName ].length - 1;
        if( idx < 0 ) {
            idx = 0;
        }
        setCodeIndex( idx );
        inputRef.current.querySelector('input').click();
        inputRef.current.querySelector('input').focus();
    }

    useEffect(() => {
        handleSendVerification( true );

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleKeyPress);
        };
    }, [dispatch]);

    return (
        <FormContainer errorMessage={verificationError} infoMessage={infoMessage}>
            {!isVerified && isVerificationSent &&
                <Aux>
                    <Relative>
                        <Hidden ref={inputRef} >
                            <Input
                                name={props.inputName}
                                type={"tel"}
                                placeholder={props.placeholder}
                                value={formData[props.inputName]}
                                screenType={props.screenType}
                                hasError={formError[props.inputName]}
                                setError={setError}
                                clearError={clearError}
                                // onChange={handleChange}
                                errorMessage={props.fieldError}
                            />
                        </Hidden>
                    </Relative>
                    <CodeBoxParent>
                        <CodeBox active={codeIndex === 0} code={formData[props.inputName].charAt(0)} onClick={handleClick}/>
                        <CodeBox active={codeIndex === 1} code={formData[props.inputName].charAt(1)} onClick={handleClick}/>
                        <CodeBox active={codeIndex === 2} code={formData[props.inputName].charAt(2)} onClick={handleClick}/>
                        <CodeBox active={codeIndex === 3} code={formData[props.inputName].charAt(3)} onClick={handleClick}/>
                        <CodeBox active={codeIndex === 4} code={formData[props.inputName].charAt(4)} onClick={handleClick}/>
                        <CodeBox active={codeIndex === 5} code={formData[props.inputName].charAt(5)} onClick={handleClick}/>
                    </CodeBoxParent>
                    <Paragraph><Text><Resend onClick={handleSendVerification}>Resend Verification</Resend></Text></Paragraph>
                </Aux>
            }
        </FormContainer>
    )
}

export default Verification;

const Relative = styled.div`
    position: relative;
`;

const Hidden = styled.div`
    position: absolute;
    top: 0;
    opacity: 0;
    transform: translateX(-100%);
    pointer-events: none;
`;

const Resend = styled.div`
    display: inline;
    cursor: pointer;
    color: #007aff;
    font-weight: 700 !important;
    margin: 20px 0;
`

const CodeBoxParent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CodeBox = styled.div`
    display: inline-block;
    width: 70px;
    height: 70px;
    padding: 10px;
    margin: 20px;
    border: ${props => props.active ? "1px solid #212121" : "none"};
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.16);
    color: #212121;
    font-size: 16px;
    line-height: 49px;
    outline: none;
    background-color: transparent;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #212121;
        width: ${props => props.active ? "2px" : 0 };
        height: ${props => props.active ? "70%" : 0 };

        animation: blink 1s step-start infinite;

        @keyframes blink {
            0%, 100% {
                opacity: 1;
            }
            50% {
                opacity: 0;
            }
        }
    }

    &:after {
        content: "${props => props.code }";
        position: absolute;
        font-size: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media (max-width: 768px) {
        width: 55px;
        height: 55px;
        padding: 5px;
        margin: 5px;

        &:after {
            font-size: 30px;
        }
    }

    @media (max-width: 500px) {
        width: 45px;
        height: 45px;
    }
`;
