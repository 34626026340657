import React from "react";
import styled from "styled-components";

const MessageContainer = (props) => {
    return (
        <Container>{props.children}</Container>
    )
}

export default MessageContainer;

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    
    @media (max-width: 768px) {
        margin: 0;
        
        & > div {
            width: 100% !important;
        }
    }
`;
