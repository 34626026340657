import { createSlice } from '@reduxjs/toolkit';

export const trackingSlice = createSlice({
    name: 'tracking',
    initialState: {
        id: "",
        hamperId: "",
        hamperCode: "",
        status: "",
        statusUpdatedAt: "",
        isComplete: false,
        startTime: "",
        endTime: "",
        trackingDetails: [],
        error: ""
    },
    reducers: {
        updateTrackingDetails: (state, action) => {
            state.id = action.payload.id;
            state.hamperId = action.payload.hamperId;
            state.hamperCode = action.payload.hamperCode;
            state.status = action.payload.status;
            state.statusUpdatedAt = action.payload.statusUpdatedAt
            state.isComplete = action.payload.isComplete;
            state.startTime = action.payload.startTime;
            state.endTime = action.payload.endTime;
            state.trackingDetails = action.payload.trackingDetails;
        },
        updateTrackingError: (state, action) => {
            state.error = action.payload.error;
        },
        getTrackingDetails: () => {}
    },
})

export const {
    updateTrackingDetails,
    updateTrackingError,
    getTrackingDetails
} = trackingSlice.actions;

export default trackingSlice.reducer;