import React from "react";
import styled from "styled-components";

const Image = (props) => {
    return (
        <StyledImage
            src={props.url}
            cssUrl={props.cssUrl}
            height={props.height}
        />
    )
}

export default Image;

const StyledImage = styled.img`
    width: 100%;
    height: ${props => props.height ? props.height : "100%"};
    background: ${props => props.cssUrl ? `url(${props.cssUrl})` : 'none'};
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
`;