import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        username: "",
        password: "",
        isAuthenticated: false,
        role: "",
        error: "",
    },
    reducers: {
        login: (state, action) => {
            state.role = action.payload.role;
            state.isAuthenticated = true;
            state.error = "";
        },
        logout: (state) => {
            state.role = "";
            state.isAuthenticated = false;
            state.error = "";
        },
        checkAuthentication: () => {},
        handleLogin: () => {},
        handleLogout: () => {},
        updateAuth: (state, action) => {
            state.role = action.payload.role;
            state.isAuthenticated = action.payload.isAuthenticated;
            state.error = action.payload.error;
        },
        updateUsername: (state, action) => {
            state.username = action.payload.username;
        },
        updatePassword: (state, action) => {
            state.password = action.payload.password;
        },
    },
})

export const {
    login,
    logout,
    checkAuthentication,
    handleLogin,
    handleLogout,
    updateAuth,
    updateUsername,
    updatePassword
} = authSlice.actions;

export default authSlice.reducer;