import React from "react";
import styled from "styled-components";

const PageTitle = (props) => {
    return (
        <Title
            alignment={props.alignment}
            margin={props.margin}
        >
            {props.children}
        </Title>
    )
}

export default PageTitle;

const Title = styled.h1`
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    text-align: ${props => props.alignment || "left" };
    margin-top: ${props => props.margin ? 0 : "27px"};
    margin-bottom: 0;
    
    @media (max-width: 768px ) {
        font-size: 25px;
        line-height: 33px;
    }
`