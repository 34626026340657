import React, {useEffect, useState} from "react";
import Aux from "../../components/Aux/Aux";
import PageContainer from "../../components/utility/containers/PageContainer";
import {
    removeEmailSuccess, removePhoneSuccess,
    sendEmailVerification,
    sendPhoneVerification,
    verifyEmailCode,
    verifyPhoneCode
} from "../../store/reducers/customer";
import Verification from "../../components/Verification/CustomerVerification/Verification";
import {useSelector} from "react-redux";
import DefaultHeader from "../../components/Headers/DefaultHeader";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import PageTitle from "../../components/utility/text/PageTitle";
import PageSubheader from "../../components/utility/text/PageSubheader";
import Spinner from "../../components/Spinner/v1/Spinner";
import SuccessVerification from "../../components/Verification/SuccessVerification";

const CustomerValidate = (props) => {
    const emailVerified = useSelector( (state) => state.customer.isEmailVerified );
    const phoneVerified = useSelector( (state) => state.customer.isPhoneNumberVerified );
    const showEmailSuccess = useSelector( (state) => state.customer.showEmailSuccess );
    const showPhoneSuccess = useSelector( (state) => state.customer.showPhoneSuccess );
    const emailAddress = useSelector( (state) => state.customer.emailAddress );
    const phoneNumber = useSelector( (state) => state.customer.phoneNumber );
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const hamperVerification = () => {
            if( emailVerified && phoneVerified && !showPhoneSuccess && !showEmailSuccess ) {
                props.nextStep();
            }
        }

        setTimeout( function() {
            hamperVerification();
        }, 1000 );
    }, [emailVerified, phoneVerified, showPhoneSuccess, showEmailSuccess, props]);

    return (
        <Aux>
            { loading && <Spinner />}
            <DefaultHeader isLoading={props.isLoading}/>
            <PageContainer>
                { !emailVerified &&
                    <Aux>
                        <PageTitle alignment={"center"}>Please verify your Email Address</PageTitle>
                        <PageSubheader alignment={"center"}>You're almost there! we sent an email to <b>{emailAddress}</b></PageSubheader>
                        <PageSubheader alignment={"center"} margin={"large"}>Input the verification code below to finish verifying your email. If you don't see it, you may need to check your spam folder.</PageSubheader>
                    </Aux>
                }
                { emailVerified && !showEmailSuccess && !phoneVerified &&
                    <Aux>
                        <PageTitle alignment={"center"}>Please verify your Phone Number</PageTitle>
                        <PageSubheader alignment={"center"}>You're almost there! we sent an message to <b>{phoneNumber}</b></PageSubheader>
                        <PageSubheader alignment={"center"} margin={"large"}>Input the verification code below to finish verifying your phone number.</PageSubheader>
                    </Aux>
                }
                {!emailVerified &&
                    <Verification
                        inputName="emailverificationcode"
                        placeholder="verification code"
                        type="Email"
                        setLoading={setLoading}
                        screenType={props.screenType}
                        sendVerification={sendEmailVerification}
                        validateCode={verifyEmailCode}
                        fieldError="Please enter a valid verification code"
                    />
                }
                {emailVerified && !showEmailSuccess && !phoneVerified &&
                    <Verification
                        inputName="phoneverificationcode"
                        placeholder="verification code"
                        setLoading={setLoading}
                        screenType={props.screenType}
                        sendVerification={sendPhoneVerification}
                        validateCode={verifyPhoneCode}
                    />
                }
                { emailVerified && showEmailSuccess && <SuccessVerification name={"Email Address"} removeSuccess={ removeEmailSuccess } /> }
                { phoneVerified && showPhoneSuccess && <SuccessVerification name={"Phone Number"} removeSuccess={ removePhoneSuccess } /> }
            </PageContainer>
            <DefaultFooter isShortContent={props.isShortContent} />
        </Aux>
    )
}

export default CustomerValidate;
