import React from "react";
import styled from "styled-components";

const Subheader = (props) => {
    return (
        <Title
            alignment={props.alignment}
            margin={props.margin}
        >
            {props.children}
        </Title>
    )
}

export default Subheader;

const Title = styled.p`
    color: #4f4f4f;
    font-size: 18px;
    line-height: 24px;
    text-align: ${props => props.alignment || "left" };
    margin: ${props => props.margin === "large" ? "20px 0" : "9px 0"};
    
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
    }
`