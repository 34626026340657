import React from 'react';
import Aux from "../../../components/Aux/Aux";
import styled from "styled-components";
import Pic from "../../../assets/images/background/404_not_found.png"
import BackgroundOverlay from "../../../components/utility/containers/BackgroundOverlay";
import DefaultHeader from "../../../components/Headers/DefaultHeader";
import DefaultFooter from "../../../components/Footers/DefaultFooter";

const NotFound = (props) => {
    return (
        <Aux>
            <DefaultHeader />
            <BackgroundOverlay />
            <Image src={Pic} alt={"404 not found"} />
            <DefaultFooter isShortContent={props.isShortContent} />
        </Aux>
    );
}

export default NotFound;

const Image = styled.img`
    position: absolute;
    z-index: -1;
    width: 100vw;
`;