import React, {useEffect, useRef} from "react";
import styled from "styled-components"

const Button = (props) => {
    const buttonRef = useRef(null);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if( buttonRef.current && props.id === "submit" ) {
                buttonRef.current.click();
            }
            else if ( props.id ) {
                document.getElementById( props.id )?.click();
            }
        }
    };

    const handleSubmit = () => {
        if( !props.hasErrors ) {
            props.submit();
        }
        else if( !props.hasErrors() ) {
            props.submit();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('mousedown', handleKeyPress);
        };
    }, []);

    return (
        <Container>
            <StyledButton className={ props.inverse ? "inverse" : ( props.white ? "white" : "" )} ref={buttonRef} type={"submit"} id={props.id} onClick={handleSubmit}>{props.children}</StyledButton>
        </Container>
    )
}

export default Button;

const Container = styled.div`
    background-color: transparent;
    display: flex;
    justify-content: center;
    margin: 10px 0;
`;

const StyledButton = styled.button`
    cursor: pointer;
    width: 424px;
    height: 49px;
    padding: 0 8px;
    border: 0;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #212121;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    outline: none;
    
    &.inverse {
        background-color: transparent;
        color: #212121;
        border: 2px solid #212121;
    }

    &.white {
        background-color: white;
        color: #212121;
        border: 2px solid #212121;
    }
    
    @media (max-width: 768px) {
        width: 90%;
    }
`;
