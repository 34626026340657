import React from "react";
import styled from "styled-components"
import ErrorMessage from "../../../utility/errorMessages/errors";
import MessageContainer from "../../../utility/MessageContainer/container";
import {Paragraph, Text} from "../../../utility/helper/Paragraph";
import WarningIcon from "../../../../assets/icons/WarningIcon";
import SuccessMessage from "../../../utility/successMessages/success";

const FormContainer = (props) => {
    return (
        <Container>
            {props.children}
            {props.errorMessage &&
                <MessageContainer>
                    <ErrorMessage>
                        {props.errorMessage}
                    </ErrorMessage>
                </MessageContainer>
            }
            {props.successMessage &&
                <SuccessMessage>
                    {props.successMessage}
                </SuccessMessage>
            }
            {props.infoMessage &&
                <MessageContainer>
                    <Paragraph>
                        <Text>
                            <InfoMessage>
                                <WarningIcon />
                                {props.infoMessage}
                            </InfoMessage>
                        </Text>
                    </Paragraph>
                </MessageContainer>
            }
        </Container>
    )
}

export default FormContainer;

const Container = styled.div`
    padding: 10px 20px;
    
    @media (max-width: 768px) {
        padding: 0;
    }
`;

const InfoMessage = styled.div`
    padding: 10px;
    width: 100%;
    background-color: #287dda;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    
    & > svg {
        color: #ffffff !important;
        fill: #ffffff !important;
        width: 40px !important;
        height: 32px !important;
        margin-right: 10px;
    }
`;
