import React from "react";
import styled from "styled-components";

const FlexContainer = (props) => {
    return (
        <Container
            direction={props.direction}
        >
            {props.children}
        </Container>
    )
}

export default FlexContainer;

const Container = styled.div`
    display: flex;
    flex-direction: ${props => props.direction || "row"};
    flex-wrap: wrap;
`;
