import React from "react";
import styled from "styled-components";

const TeaserCard = (props) => {
    return (
        <Container>
            {props.children}
        </Container>
    )
}

export default TeaserCard;

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    height: 168px;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 2px 0 10px rgba(3,3,3,0.1);
    opacity: 0.88;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
