import React, {useRef, useState, useEffect} from "react";
import styled from "styled-components"
import Aux from "../../../Aux/Aux";

const Input = ({Icon, ...props}) => {
    const [isActive, setIsActive] = useState(false);
    const [inputValue, setInputValue] = useState(props.value || "");
    const inputRef = useRef(null);

    const handleClick = (event) => {
        if ( ( inputRef.current && inputRef.current.contains(event.target) ) ) {
            props.clearError( inputRef.current.getAttribute("name") );
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    };

    const handleOnBlur = () => {
        setIsActive( false );
    };

    const handleOnFocus = (event) => {
        props.clearError( event.target.getAttribute("name") );
        setIsActive( true );
    };

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setInputValue( value )
        if( typeof props.onChange === 'function' ) {
            props.onChange( name, value );
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    let inputClasses = "",
        labelClass = "";

    if( isActive ) {
        inputClasses = "active ";
    }
    if( props.hasError ) {
        inputClasses += "error ";
        labelClass = "error";
    }

    return (
        <Aux>
            <InputParent
                isHalf={props.half}
                side={props.side}
                profile={props.profile}
                screenType={props.screenType}
            >
                { props.type !== "checkbox" && !props.profile &&
                    <FieldLabel
                        className={ labelClass }
                        isHalf={props.half}
                        screenType={props.screenType}
                    >
                        {props.labelText} {props.required && <FieldRequired>*</FieldRequired>}
                    </FieldLabel>
                }
                <WrapperDiv>
                    {props.type === "checkbox" &&
                        <Checkbox
                            screenType={props.screenType}
                            className={inputClasses}
                        >
                            <FormInput
                                name={props.name}
                                className={inputClasses}
                                type={props.type}
                                readOnly={props.readOnly || false}
                                autoComplete={ props.readOnly ? !props.readOnly : true}
                                ref={inputRef}
                                placeholder={props.placeholder}
                                value={inputValue}
                                onChange={handleInput}
                                onFocus={handleOnFocus}
                                onBlur={handleOnBlur}
                                profile={props.profile}
                                isHalf={props.half}
                                side={props.side}
                                screenType={props.screenType}
                            />
                            <label>
                                { props.labelText }
                            </label>
                        </Checkbox>
                    }
                    {props.type !== "checkbox" &&
                        <FormInput
                            name={props.name}
                            className={inputClasses}
                            type={props.type}
                            readOnly={props.readOnly || false}
                            autoComplete={ props.readOnly ? !props.readOnly : true}
                            ref={inputRef}
                            placeholder={props.placeholder}
                            value={inputValue}
                            onChange={handleInput}
                            onFocus={handleOnFocus}
                            onBlur={handleOnBlur}
                            profile={props.profile}
                            isHalf={props.half}
                            side={props.side}
                            screenType={props.screenType}
                        />
                    }
                    <InputIcon
                        className={ labelClass }
                    >
                        <Aux>
                            {props.children}
                        </Aux>
                    </InputIcon>
                </WrapperDiv>
                {props.hasError &&
                    <FieldError
                        isHalf={props.half}
                        screenType={props.screenType}
                    >
                        {props.errorMessage}
                    </FieldError>
                }
            </InputParent>
        </Aux>
    )
}

export default Input;

const InputParent = styled.div`
    width: ${props => (props.isHalf && props.screenType !== "Mobile") ? 50 : 100}%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: ${props => props.profile ? "flex-end" : ((props.side && props.screenType !== "Mobile") ? props.side : "center")};
    margin: ${props => props.profile ? "0" : ((props.isHalf && props.screenType !== "Mobile") ? 0 : "20px 0")};
`;

const Checkbox = styled.div`
    width: ${props => props.screenType === "Mobile" ? "100%" : (props.isHalf ? "204px" : "424px")};
    border: 0;
    box-sizing: border-box;
    color: #212121;
    font-size: 16px;
    outline: none;
    display: flex;
    align-items: center;

    & input[type=checkbox] {
        margin-right: 15px;
        appearance: none;
        -webkit-appearance: none;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
    }

    /* Style when the checkbox is checked */
    input[type="checkbox"]:checked {
        background-color: #212121;
        border-color: #ffffff;
    }

    input[type="checkbox"]:checked::before {
        content: '';
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 3px);
        width: 6px;
        height: 16px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    &.error input[type=checkbox] {
        outline: 1px solid #ff0000;
        outline-offset: -2px;
    }
`;

const FormInput = styled.input`
    width: ${props => props.profile ? "100%" : (props.type === "checkbox" ? "40px" : (props.screenType === "Mobile" ? "100%" : (props.isHalf ? "204px" : "424px")))};
    height: ${props => props.type === "checkbox" ? "30px" : "49px"};
    padding: 0 15px;
    border: 0;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.16);
    color: #212121;
    font-size: 16px;
    line-height: 49px;
    outline: none;
    background-color: ${props => props.readOnly ? "#F3F3F3" : "transparent"};
    text-align: ${props => props.profile ? "right" : "left"};

    &.active {
        border: 1px solid #212121;
    }

    &.error {
        border: 1px solid #ff0000;
        color: #eb8c9d;
    }

    &:focus {
        outline: none;
    }

    transition: all .5s;
`;

const FieldLabel = styled.div`
    margin-bottom: 5px;
    color: #212121;
    width: ${props => props.screenType === "Mobile" ? "90%" : (props.isHalf ? "204px" : "424px")};
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    ${props => props.hide ? "display: none" : ""}

    &.error {
        color: #ff0000;
    }
`;

const FieldRequired = styled.div`
    display: inline-block;
    color: #ff0000;
`

const FieldError = styled.div`
    margin-top: 5px;
    width: ${props => props.screenType === "Mobile" ? "90%" : (props.isHalf ? "204px" : "424px")};
    color: #ff0000;
    font-size: 12px;
    line-height: 16px;
`;

const WrapperDiv = styled.div`
    position: relative;

    @media (max-width: 768px) {
        width: 90%;
    }
`;

const InputIcon = styled.div`
    position: absolute;
    width: fit-content;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    &.error svg {
        color: #eb8c9d;
        fill: #eb8c9d;
    }
`
