import React, {useState} from "react";
import Aux from "../../components/Aux/Aux";
import {useDispatch, useSelector} from "react-redux";
import DefaultHeader from "../../components/Headers/DefaultHeader";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import PageWidthContainer from "../../components/utility/containers/PageWidthContainer";
import {Card, CardSmall, ImageCenter} from "../../components/utility/containers/Card";
import Button from "../../components/Form/Button/v1/Button";
import styled from "styled-components";
import FlexContainer from "../../components/utility/containers/FlexContainer";
import Input from "../../components/Form/Input/v1/Input";
import FormContainer from "../../components/Form/Container/v1/FormContainer";
import {signup} from "../../store/reducers/signup";
import Spinner from "../../components/Spinner/v1/Spinner";
import {changePassword, updateCustomerProfile} from "../../store/reducers/customer";
import {PasswordText, Text} from "../../components/utility/helper/Paragraph";
import PasswordStrength from "../../components/Signup/PasswordStrength";
import Calendar from "../../components/Calendar/Calendar";
import Back from "../../components/Back/Back";

const CustomerSubscription = (props) => {
    const [loading, setLoading] = useState( false );
    const customerInfo = useSelector( (state) => state.customer );
    const allCanceled = (customerInfo.subscriptions.filter( subscription => subscription.status === "canceled").length === customerInfo.subscriptions.length);
    const invoicePaidAt = new Date(customerInfo.latestInvoice.invoicePaidAt);
    const nextInvoicePaidAt = new Date(customerInfo.latestInvoice.invoicePaidAt);
    nextInvoicePaidAt.setMonth(nextInvoicePaidAt.getMonth() + 1); // Set the date to the next month
    const nextMonth = nextInvoicePaidAt.toLocaleString('en-US', { month: 'long' });
    const year = nextInvoicePaidAt.getFullYear();

    const handleCheckoutPayment = () => {
        window.location.href = process.env.REACT_APP_CUSTOMER_CHECKOUT_PAYMENT;
    }

    const handlePortalPayment = () => {
        window.location.href = process.env.REACT_APP_CUSTOMER_UPDATE_PAYMENT;
    }

    return (
        <Aux>
            { loading && <Spinner /> }
            <DefaultHeader />
            <Back to={'/customer'} />
            <FlexContainer direction={props.screenType === "Desktop" ? "row" : "column"}>
                <PageWidthContainer
                    width={ props.screenType === "Desktop" ? 50 : 100 }
                    display={true}
                >
                    <MarginContainer>
                        <ImageCenter>
                            <Card>
                                <TableHeader>Subscription Status</TableHeader>
                                <Table>
                                    <TBody>
                                        <tr>
                                            <TD left={true}><TDContent>Status</TDContent></TD>
                                            <TD right={true} align={"right"}>
                                                {allCanceled ? "Cancelled" : "Active"}
                                            </TD>
                                        </tr>
                                        <tr>
                                            <TD left={true}><TDContent>Pickup Date</TDContent></TD>
                                            <TD right={true}
                                                align={"right"}>{customerInfo.serviceSchedule?.pickUpDay}</TD>
                                        </tr>
                                        <tr>
                                            <TD left={true}><TDContent>Dropoff Date</TDContent></TD>
                                            <TD right={true}
                                                align={"right"}>{customerInfo.serviceSchedule?.dropOffDay}</TD>
                                        </tr>
                                        <tr>
                                            <TD left={true}><TDContent>Pickup Frequency</TDContent></TD>
                                            <TD right={true}
                                                align={"right"}>{customerInfo.serviceSchedule?.frequency}</TD>
                                        </tr>
                                    </TBody>
                                </Table>
                            </Card>
                        </ImageCenter>
                    </MarginContainer>
                </PageWidthContainer>
                <PageWidthContainer
                    width={ props.screenType === "Desktop" ? 50 : 100 }
                    display={true}
                >
                    <MarginContainer>
                        <ImageCenter>
                            <Card>
                                <TableHeader>Payment Details</TableHeader>
                                <Table>
                                    <TBody>
                                        <tr>
                                            <TD left={true}><TDContent>Premium</TDContent></TD>
                                            <TD right={true} align={"right"}>
                                                ${(parseFloat(customerInfo.latestInvoice.total) / 100 ).toFixed(2)}
                                            </TD>
                                        </tr>
                                        <tr>
                                            <TD left={true}><TDContent>Last Payment</TDContent></TD>
                                            <TD right={true} align={"right"}>
                                                {invoicePaidAt.toLocaleDateString('en-US')}
                                            </TD>
                                        </tr>
                                    </TBody>
                                </Table>
                                <ButtonParent>
                                    { !allCanceled && <Button submit={ handlePortalPayment }>Manage Subscription</Button> }
                                    { allCanceled && <Button submit={ handleCheckoutPayment }>Add Subscription</Button> }
                                </ButtonParent>
                            </Card>
                        </ImageCenter>
                    </MarginContainer>
                </PageWidthContainer>
                <PageWidthContainer
                    width={ props.screenType === "Desktop" ? 50 : 100 }
                    display={true}
                >
                    <MarginContainer>
                        <ImageCenter>
                            <Card>
                                <TableHeader>Subscription Renewal Date {nextMonth} {year} </TableHeader>
                                <Calendar invoiceDate={nextInvoicePaidAt} />
                            </Card>
                        </ImageCenter>
                    </MarginContainer>
                </PageWidthContainer>
            </FlexContainer>
            <DefaultFooter isShortContent={props.isShortContent}/>
        </Aux>
    )
}

export default CustomerSubscription;

const ButtonParent = styled.div`
    width: 100%;
`;

const MarginContainer = styled.div`
    margin: 30px;
`;

const TDContent = styled.div`
    display: flex;
    align-items: center;
`;

const TableHeader = styled.div`
    margin: 10px 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
`;

const Table = styled.table`
    width: 100%;
`;

const TBody = styled.tbody`
`;

const TD = styled.td`
    text-align: ${props => props.align || "left"};
    padding: 10px 15px;
    font-size: 14px;
    font-weight: ${props => props.left ? 300 : 700};
    font-style: italic;
    line-height: 18px;
`;
