import React, {useEffect, useState} from 'react';
import DefaultHeader from "../../../components/Headers/DefaultHeader";
import Spinner from "../../../components/Spinner/v1/Spinner";
import PageContainer from "../../../components/utility/containers/PageContainer";
import PageTitle from "../../../components/utility/text/PageTitle";
import {useLocation} from "react-router-dom";
import Aux from "../../../components/Aux/Aux";
import {useDispatch, useSelector} from "react-redux";
import {getTrackingDetails} from "../../../store/reducers/tracking";
import DefaultFooter from "../../../components/Footers/DefaultFooter";
import FormContainer from "../../../components/Form/Container/v1/FormContainer";
import PageWidthContainer from "../../../components/utility/containers/PageWidthContainer";
import {
    Card,
    CardDetails,
    CardHeader,
    CardSmall,
    CardTitle,
    ImageCenter
} from "../../../components/utility/containers/Card";
import Image from "../../../components/Image/v1/Image";
import Hamper from "../../../assets/images/images/Hamper.png"
import TrackingBreadcrumb from "../../../components/Tracking/TrackingBreadcrumb";
import TrackingTable from "../../../components/Tracking/TrackingTable";
import FlexContainer from "../../../components/utility/containers/FlexContainer";

const TrackingRoute = (props) => {
    const [loading, setLoading] = useState( true );
    const [hamperCode, setHamperCode] = useState( "" );
    const [notValidHamper, setNotValidHamper ] = useState( false );
    const location = useLocation();
    const dispatch = useDispatch();
    const error = useSelector( (state) => state.tracking.error );
    const trackingDetails = useSelector( (state) => state.tracking.trackingDetails );
    const status = useSelector( (state) => state.tracking.status );
    const statusUpdatedAt = useSelector( (state) => state.tracking.statusUpdatedAt );
    const pickedUpTime = trackingDetails.filter( details => details.status === "PICKED_UP_FROM_CUSTOMER")?.[0]?.statusUpdatedAt;
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    let currentlyActive = trackingDetails?.length || 0;

    if( currentlyActive > 2 ) {
        currentlyActive = currentlyActive + 4;
    }
    else if ( currentlyActive === 2 ) {
        const pastDate = new Date(statusUpdatedAt);
        const currentDate = new Date();
        const diffInMs = currentDate - pastDate;

        // Convert milliseconds to hours (1 hour = 60 * 60 * 1000 milliseconds)
        const diffInHours = diffInMs / (1000 * 60 * 60);

        // Check if 2 hours have passed
        if( diffInHours >= 2 ) {
            currentlyActive = currentlyActive + 1;
        }
        if( diffInHours >= 4 ) {
            currentlyActive = currentlyActive + 1;
        }
        if( diffInHours >= 6 ) {
            currentlyActive = currentlyActive + 1;
        }
    }

    useEffect(() => {
        let pathNameArr = location.pathname.split("/");
        const code = pathNameArr[ pathNameArr.length - 1];
        setHamperCode( code );
        if( /^[a-zA-Z0-9]{5}$/.test( code ) ) {
            setNotValidHamper( false );
            dispatch( getTrackingDetails( { hamperCode: code, setLoading }) )
        }
        else {
            setNotValidHamper( true );
        }
    }, [setLoading, setHamperCode, setNotValidHamper, dispatch]);

    return (
        <Aux>
            {loading && <Spinner />}
            <DefaultHeader />
            <PageContainer>
                <PageTitle>Track Your Laundry</PageTitle>
                <FormContainer errorMessage={error}>
                    { notValidHamper && <PageTitle>We could Not find Hamper Code: {} in our system</PageTitle>}
                    { !notValidHamper && !error && trackingDetails?.length !== 0 && props.screenType === "Desktop" &&
                        <Aux>
                            <TrackingBreadcrumb currentlyActive={currentlyActive} screenType={props.screenType} details={trackingDetails}/>
                            <FlexContainer>
                                <PageWidthContainer display={true} width={"30"}>
                                    <CardSmall>
                                        <ImageCenter>
                                            <Image
                                                cssUrl={Hamper}
                                                height={"100%"}
                                            />
                                        </ImageCenter>
                                        <CardHeader>Hamper Details</CardHeader>

                                        <CardTitle>Code</CardTitle>
                                        <CardDetails>{hamperCode}</CardDetails>

                                        <CardTitle>Last Update</CardTitle>
                                        <CardDetails>{(new Date(statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}</CardDetails>

                                        <CardTitle>Picked Up</CardTitle>
                                        <CardDetails>{(new Date(pickedUpTime)).toLocaleString('en-US', options).replace('at', '')}</CardDetails>

                                        <CardTitle>Status</CardTitle>
                                        { status === "PICKED_UP_FROM_CUSTOMER" && <CardDetails>Picked Up</CardDetails> }
                                        { status === "DROPPED_OFF_AT_LAUNDROMAT" && <CardDetails>At Laundromat</CardDetails> }
                                        { status === "PICKED_UP_FROM_LAUNDROMAT" && <CardDetails>In Route to Drop Off</CardDetails> }
                                        { status === "DELIVERED_TO_CUSTOMER" && <CardDetails>Picked Up</CardDetails> }
                                    </CardSmall>
                                </PageWidthContainer>
                                <PageWidthContainer display={true} width={"70"}>
                                    <TrackingTable details={trackingDetails} active={currentlyActive} />
                                </PageWidthContainer>
                            </FlexContainer>
                        </Aux>
                    }
                    { !notValidHamper && !error && trackingDetails?.length !== 0 && props.screenType !== "Desktop" &&
                        <Aux>
                            <TrackingBreadcrumb currentlyActive={currentlyActive} screenType={props.screenType} details={trackingDetails}/>
                            <ImageCenter>
                                <Card>
                                    <ImageCenter>
                                        <Image
                                            cssUrl={Hamper}
                                            height={"100%"}
                                        />
                                    </ImageCenter>
                                    <CardHeader>Hamper Details</CardHeader>

                                    <CardTitle>Code</CardTitle>
                                    <CardDetails>{hamperCode}</CardDetails>

                                    <CardTitle>Last Update</CardTitle>
                                    <CardDetails>{(new Date(statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}</CardDetails>

                                    <CardTitle>Picked Up</CardTitle>
                                    <CardDetails>{(new Date(pickedUpTime)).toLocaleString('en-US', options).replace('at', '')}</CardDetails>

                                    <CardTitle>Status</CardTitle>
                                    { status === "PICKED_UP_FROM_CUSTOMER" && <CardDetails>Picked Up</CardDetails> }
                                    { status === "DROPPED_OFF_AT_LAUNDROMAT" && <CardDetails>At Laundromat</CardDetails> }
                                    { status === "PICKED_UP_FROM_LAUNDROMAT" && <CardDetails>In Route to Drop Off</CardDetails> }
                                    { status === "DELIVERED_TO_CUSTOMER" && <CardDetails>Picked Up</CardDetails> }
                                </Card>
                            </ImageCenter>
                        </Aux>
                    }
                </FormContainer>
            </PageContainer>
            <DefaultFooter isShortContent={props.isShortContent} />
        </Aux>
    );
};

export default TrackingRoute;

