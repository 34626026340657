import React from "react";
import Aux from "../../components/Aux/Aux";
import PageContainer from "../../components/utility/containers/PageContainer";
import PageTitle from "../../components/utility/text/PageTitle";
import Button from "../../components/Form/Button/v1/Button";
import {Card, CardParent} from "../../components/utility/containers/Card";
import Checkmark from "../../assets/icons/Checkmark";
import PageSubheader from "../../components/utility/text/PageSubheader";
import DefaultHeader from "../../components/Headers/DefaultHeader";
import DefaultFooter from "../../components/Footers/DefaultFooter";

const PaymentStep = (props) => {
    const handleCheckoutSession = () => {
        window.location.href = process.env.REACT_APP_CUSTOMER_CHECKOUT_PAYMENT;
    }

    return (
        <Aux>
            <DefaultHeader />
            <PageContainer>
                <CardParent>
                    <Card>
                        <Checkmark />
                        <PageTitle alignment={"center"}>Hamper Verified</PageTitle>
                        <PageSubheader alignment={"center"}>Your Hamper was verified successfully!</PageSubheader>
                        <Button submit={handleCheckoutSession}>Proceed to Payment</Button>
                    </Card>
                </CardParent>
            </PageContainer>
            <DefaultFooter isShortContent={props.isShortContent} />
        </Aux>
    )
}

export default PaymentStep;

