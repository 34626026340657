import React from "react";
import styled from "styled-components";

const PageWidthContainer = (props) => {
    return (
        <Container
            width={props.width}
            display={props.display}
            height={props.height}
            id={props.id}
        >
            {props.children}
        </Container>
    )
}

export default PageWidthContainer;

const Container = styled.div`
    position: relative;
    width: ${props => props.width}%;
    min-height: ${props => props.height ? "auto" : "100%"};
    display: ${props => props.display ? "flex" : "none" };
    flex-direction: column;
    height: ${props => props.height ? props.height : "auto"};
`;
