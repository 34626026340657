import { createSlice } from '@reduxjs/toolkit';

export const employeeSlice = createSlice({
    name: 'employee',
    initialState: {
        error: "",
        uniqueAddresses: [],
        pendingDeliveries: []
    },
    reducers: {
        updateAddresses: (state, action) => {
            state.error = "";
            state.uniqueAddresses = action.payload.uniqueAddresses;
            state.pendingDeliveries = action.payload.pendingDeliveries;
        },
        clearAddresses: (state) => {
            state.error = "";
            state.uniqueAddresses = "";
            state.pendingDeliveries = "";
        },
        addressSearch: () => {},
    },
})

export const {
    updateAddresses,
    clearAddresses,
    addressSearch

} = employeeSlice.actions;

export default employeeSlice.reducer;