import React, {useEffect, useState} from "react";
import styled from "styled-components"
import {useNavigate} from "react-router-dom";

const Back = (props) => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate( props.to );
    }

    return (
        <BackLink onClick={handleBackClick}>
            {"<--"} {props.text || "Back"}
        </BackLink>
    );
}

export default Back;

const BackLink = styled.div`
    cursor: pointer;
    margin: 10px 50px;
    
    &:hover {
        color: #287dda;
    }
    
    @media (max-width: 768px) {
        margin: 10px 20px;
    }
`;
