import React, {useEffect} from "react";
import Aux from "../Aux/Aux";
import Checkmark from "../../assets/icons/Checkmark";
import PageTitle from "../utility/text/PageTitle";
import PageSubheader from "../utility/text/PageSubheader";
import {useDispatch} from "react-redux";
import {Card, CardParent} from "../utility/containers/Card";

const SuccessVerification = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const removeMessage = () => {
            dispatch( props.removeSuccess() )
        }

        setTimeout( function() {
            removeMessage()
        }, 2500 );
    }, [dispatch]);

    return (
        <Aux>
            <CardParent>
                <Card>
                    <Checkmark />
                    <PageTitle alignment={"center"}>{props.name} Verified</PageTitle>
                    <PageSubheader alignment={"center"}>Your {props.name.toLowerCase()} was verified successfully!</PageSubheader>
                </Card>
            </CardParent>
        </Aux>
    )
}

export default SuccessVerification;
